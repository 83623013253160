import React from 'react';
import styled from 'styled-components';
import PT from 'prop-types';

import { H1, H3, Paragraph } from 'site/src/components/styled';
import GridIcon from 'shared/designTokens/icons/ui/small/GridIcon';
import ArrowRightIcon from 'shared/designTokens/icons/ui/small/ArrowRightIcon';
import { _small, _medium, _large } from 'shared/vars';

export const SubSubTitle = styled(H3)`
	margin: 0;
	margin-top: 0 !important;
	font-family: "Proxima Nova", sans-serif;
	font-size: 22px;
	line-height: 127.273%;

	${_medium} {
		line-height: 163.636%;
	}
`;

export const SubTitle = styled(H3)`
	margin-top: 56px;
	margin-bottom: 28px;
	line-height: 116.667%;

	${_large} {
		font-size: 28px;
		line-height: 1.14;
	}
`;

export const StyledList = styled.ul`
	font-size: 16px;
	line-height: 1.5;
	padding: 0 0 0 24px;
	margin: 7px 0 0;

	${_medium} {
		padding-left: 24px;
	}

	li:not(:first-child) {
		margin-top: 8px;
	}
`;

export const StyledLink = styled.a`
	font: 16px/24px "Proxima Nova", sans-serif;
	color: ${props => props.theme.pokiBlue};
	text-decoration: none;
`;

export const Callout = styled.div`
	background-color: ${props => props.theme.grey7};
	padding: 21px 20px;
	margin: 24px -20px 48px;
	border-radius: 12px;

	& > iframe {
		width: 100%;
		aspect-ratio: 16 / 9;
	}

	${_small} {
		padding: 24px;
		margin: 24px 0 48px;
	}

	${_large} {
		padding: 64px;
	}

	${StyledList} li:not(:first-child) {
		margin-top: 16px;
	}
`;

export const CalloutTitle = styled(SubSubTitle)`
	margin: 0;
`;

export const CalloutRow = styled(Callout)`
	display: flex;
	flex-direction: column;
	gap: 24px;

	margin: 24px -20px 48px;

	${_small} {
		width: 100%;
		margin: 24px 0 48px;
	}

	${_medium} {
		flex-direction: row;
		padding: 24px;
	}

	${_large} {
		padding: 64px;
	}

	img {
		width: 100%;
		max-width: 400px;
		height: auto;
		object-fit: contain;
	}
`;

export const CallOutColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	flex: 1;
`;

export const Main = styled.main`
	max-width: 1024px;
	margin: 0 auto;
	padding: 0 40px;
	padding-bottom: 72px;
`;

export const IframeContainer = styled.div`
	margin-top: 24px;
	aspect-ratio: 16 / 9;
`;

export const StyledIframe = styled.iframe`
	width: 100%;
	height: 100%;
	border: none;
`;

const Nav = styled.nav`
	width: 100%;
	display: flex;
	padding: 24px 10px;
	justify-content: space-between;
	max-width: 944px;
	margin: 0 auto;

	${_medium} {
		padding: 24px 0;
	}
`;

const Direction = styled.span`
	text-transform: uppercase;
	color: ${props => props.theme.grey5};
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;
`;

const NavLink = styled.a`
	display: flex;
	align-items: flex-end;
	gap: 12px;

	${_medium} {
		gap: 24px;
	}
`;

const NavText = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 18px;
	line-height: 24px;
	gap: 4px;
	font-weight: 700;

	${_medium} {
		font-size: 21px;
	}
`;

const RightNavText = styled(NavText)`
	text-align: right;
`;

export const ParentDirectory = styled.a`
	display: flex;
	margin-left: 40px;
	line-height: 24px;
	align-items: center;
	gap: 24px;
	font-size: 18px;
	font-weight: 700;

	${_medium} {
		font-size: 21px;
	}
`;

export const Title = styled(H1)`
	margin-top: 16px;
	margin-bottom: 16px;

	${_medium} {
		margin-top: 40px;
		margin-bottom: 24px;
		text-align: center;
	}
`;

export const Navigation = ({ nextLink, nextLabel }) => (
	<Nav>
		<NavLink href="/guide">
			<GridIcon />
			<NavText>
				<Direction>Back to</Direction>
				<span>All topics</span>
			</NavText>
		</NavLink>
		{nextLink !== undefined && nextLabel !== undefined && (
			<NavLink href={nextLink}>
				<RightNavText>
					<Direction>Next</Direction>
					<span>{nextLabel}</span>
				</RightNavText>
				<ArrowRightIcon />
			</NavLink>
		)}
	</Nav>
);
Navigation.defaultProps = {
	nextLink: undefined,
	nextLabel: undefined,
};
Navigation.propTypes = {
	nextLink: PT.string,
	nextLabel: PT.string,
};

export const IconContainer = styled.div`
	background-color: ${props => props.backgroundColor};
	border-radius: 48px;
	padding: 24px;
	display: inline-flex;
	flex-grow: 0;
	[fill] {
		fill: ${props => props.theme.denimBlue};
	}

	& > svg {
		width: 36px;
		height: 36px;
	}
`;

export const ContentParagraph = styled(Paragraph)`
	margin-bottom: 1em;

	strong {
		color: inherit;
	}
`;
