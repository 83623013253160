import { ofType } from 'redux-observable';
import { of, fromEvent, merge, EMPTY, map, take, ignoreElements, tap, filter, switchMap } from 'rxjs';

import { openModal, hydrated, setViewport, toggleNavigation, preventPageLeave, closeModal, clearPermalinkKeyValues, copyPermalink, openToast, locationChanged } from 'app/src/actions/client';
import { ToastTypes } from 'app/src/components/ui/Toast';
import { pushEvent } from 'app/src/utils/tracking';
import { retrieveUserDetails } from 'app/src/epics/user';
import { selectImpersonator, selectUser } from 'app/src/selectors/user';
import { selectModal } from 'app/src/selectors/client';
import { createAuthorizedApiEpic } from 'app/src/utils/api';
import getApiUrl from 'app/src/utils/getApiUrl';

import { isMobile } from 'shared/vars';
import _ from 'shared/copy';

export function setViewportEpic(action$) {
	return merge(
		action$.pipe(ofType(hydrated.type), take(1)),
		fromEvent(window, 'resize'),
	).pipe(
		map(() => setViewport({
			height: window.innerHeight,
			width: window.innerWidth,
		})),
	);
}

export function closeModalOnNavigateEpic(action$, state$) {
	return action$.pipe(
		ofType(locationChanged.type),
		filter(({ payload }) => payload?.action === 'PUSH'),
		switchMap(() => {
			const modal = selectModal(state$.value);

			if (modal && modal.mustCloseOnLocationChanged) {
				return of(closeModal());
			}

			return EMPTY;
		}),
	);
}

export function closeNavigationOnNavigateEpic(action$) {
	if (isMobile) {
		return action$.pipe(
			ofType(locationChanged.type),
			filter(({ payload }) => payload?.action === 'PUSH'),
			map(() => toggleNavigation({ force: false })),
		);
	}

	return EMPTY;
}

export function preventPageLeaveEpic(action$) {
	return action$.pipe(
		ofType(preventPageLeave.type),
		tap(({ toggle }) => {
			const onbeforeunload = () => _`leavePageConfirmationDesc`; // This text only actually appears in very old browsers. Usually its a default
			window.onbeforeunload = toggle ? onbeforeunload : null;
		}),
		ignoreElements(),
	);
}

export function preventPageLeaveSafetyEpic(action$) {
	// If for whatever reason we do a locationChanged.type (e.g. maybe we log out or some other redirect)
	// make sure we are not stuck on that page
	return action$.pipe(
		ofType(locationChanged.type),
		filter(({ payload }) => payload?.action === 'PUSH'),
		map(() => preventPageLeave({ toggle: false })),
	);
}

export function clearPermalinkKeyValuesEpic(action$) {
	return action$.pipe(
		ofType(locationChanged.type),
		filter(({ payload }) => payload?.action === 'PUSH'),
		map(() => clearPermalinkKeyValues()),
	);
}

export function copyPermalinkEpic(action$, state$) {
	return action$.pipe(
		ofType(copyPermalink.type),
		switchMap(async () => {
			pushEvent('permalink', 'copy');

			const keyValues = state$.value?.client?.permalinkKeyValues || [];
			const url = new URL(window.location.href);

			const urlSearchParams = new URLSearchParams();

			Object.entries(keyValues).forEach(([key, value]) => {
				urlSearchParams.set(key, value);
			});

			url.hash = urlSearchParams.toString().replace('%23', '');
			const permalink = url.toString();

			try {
				await navigator.clipboard.writeText(permalink);
				return openToast({ body: _`permalinkSuccess` });
			} catch (e) {
				return openToast({ body: _`permalinkFailed`, toastType: ToastTypes.WARNING });
			}
		}),
	);
}

export function mustAcceptPrivacyAddendumEpic(action$, state$) {
	return action$.pipe(
		ofType(retrieveUserDetails.success.type),
		switchMap(() => {
			const user = selectUser(state$.value);
			const impersonator = selectImpersonator(state$.value);

			if (!impersonator && user?.team?.verified && user?.team?.read_privacy_addendum === 'not-accepted' && window.location.pathname !== '/privacy-addendum' && window.location.pathname !== '/complete-signup') {
				return of(openModal({ key: 'accept-privacy-addendum', mustCloseOnLocationChanged: false }));
			}

			return EMPTY;
		}),
	);
}

export function mustAcceptRevenueShareAddendumEpic(action$, state$) {
	if (isMobile) {
		return EMPTY;
	}

	return action$.pipe(
		ofType(retrieveUserDetails.success.type),
		switchMap(() => {
			const user = selectUser(state$.value);
			const impersonator = selectImpersonator(state$.value);

			if (!impersonator && user?.team?.read_direct_landing_addendum === 'not-accepted' && window.location.pathname !== '/complete-signup') {
				return of(openModal({ key: 'revenue-share-addendum', mustCloseOnLocationChanged: false }));
			}

			return EMPTY;
		}),
	);
}

export const translate = createAuthorizedApiEpic(
	'translate',
	(callApi, { text }) => callApi({
		url: getApiUrl('devs', '_translate'),
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			input: [{ text }],
		}),
	}),
	null,
	{ allowParallel: true, cacheSeconds: Infinity },
);

export const getRevenueShareAddendumPerTeam = createAuthorizedApiEpic(
	'client/get_revenue_share_addendum',
	callApi => callApi({
		url: getApiUrl('devs', '_direct-landing-addendum'),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
		},
	}),
);
