import React from 'react';
import styled from 'styled-components';

import Tooltip from 'shared/components/Tooltip';

const Point = styled.div`
	display: block;
	position: relative;
	border-radius: 100%;
	transition: 0.1s ease-in-out;
	flex-shrink: 0;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 12px;

	& + & {
		margin-left: 0;
	}

	&:focus {
		outline: none;
	}

	> svg [fill] {
		transition: 0.1s ease-in-out;
		fill: ${props => props.theme.grey5};
	}

	${props => (props.$warning ? `
	> svg [fill] {
		fill: ${props.theme.yellow3};
	}
	` : '')}

	${props => (!props.$disabled ? `
	&:hover,
	&:focus {
		> svg [fill] {
			fill: ${props.theme.pokiBlue};
		}
	}
	` : `
	> svg [fill] {
		fill: ${props.theme.grey7};
	}
	`)}
`;

const InfoPoint = props => {
	const { className, icon: Icon, children, disabled = false, warning = false } = props;

	return (
		<Point
			className={className}
			$disabled={disabled}
			$warning={warning}
		>
			<Tooltip
				disabled={disabled}
				content={children}
				placement="top-end"
				icon={<Icon />}
				arrow
			/>
		</Point>
	);
};

export default InfoPoint;
