import React from 'react';
import { Helmet } from 'react-helmet';

import { Container, BackgroundImage, Page } from 'site/src/components/styled';
import MarketingHeader from 'site/src/components/MarketingHeader';
import ArrowLeftIcon from 'shared/designTokens/icons/ui/small/ArrowLeftIcon';
import { ParentDirectory, CalloutRow, CallOutColumn, Main, Navigation, Title, CalloutTitle, SubTitle, ContentParagraph } from 'site/src/components/guide.styled';
import SignUpCTA from 'site/src/components/SignUpCTA';
import MarketingFooter from 'shared/components/MarketingFooter';
import getCDNImage from 'site/src/utils/getCDNImage';

const GuideThumbnailPage = () => (
	<Container>
		<Helmet key="GuideContentPage">
			<title>Poki for Developers - Web game guide</title>
		</Helmet>
		<BackgroundImage center backgroundUrl="/images/header_heart.svg" />
		<MarketingHeader />
		<Page>
			<ParentDirectory href="/guide"><ArrowLeftIcon />Overview</ParentDirectory>
			<Main>
				<Title>Game thumbnail</Title>
				<ContentParagraph extraLarge>
					You created an amazing game, congrats! Now the hard part, attracting players. On web, this can be especially difficult because you are competing for the attention of our players while they’re surrounded by other games. That’s why you need a great thumbnail, to make a good first impression, and to help recognize your game.
				</ContentParagraph>

				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>The thumbnail impacts your game’s success.</CalloutTitle>
						<ContentParagraph large>
							A good thumbnail can have a lot of impact on your game’s success on our platform. See one of the success stories of a thumbnail update in the graph here. We’ve listed some best practices below to help you with your design.
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="gameplay graph" src={getCDNImage({ src: '/images/guide/thumbnail-gameplays.png', width: 800 })} />
					</div>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Show what’s on the inside</CalloutTitle>
						<ContentParagraph large>
							Your thumbnail should reflect what the game is about. Imagine your thumbnail as a store window. If you sell plants, put a plant in the window. If you sell books, put a book in the window. The visuals in the thumbnail should closely relate to the visuals in your game. If the two don’t relate, players will feel like they’ve been directed to a wrong game and this could hurt conversion to play or gameplay time.
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="gameplay" src={getCDNImage({ src: '/images/guide/thumbnail-inside.png', width: 800 })} />
					</div>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Embrace simplicity</CalloutTitle>
						<ContentParagraph large>
							Don’t overcrowd your thumbnail with too many details or ideas. Have one clear foreground object. Stick to the essentials like a main character or an important gameplay element.
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="simplicity example" src={getCDNImage({ src: '/images/guide/thumbnail-simplicity.png', width: 800 })} />
					</div>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Keep it together</CalloutTitle>
						<ContentParagraph large>
							If your game is part of a series or saga, make sure you have continuation in your thumbnails too. Help players recognise thumbnails of the other games in your saga if they enjoyed playing a first entry.
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="icon in series" src={getCDNImage({ src: '/images/guide/thumbnail-series.png', width: 800 })} />
					</div>
				</CalloutRow>

				<SubTitle>The Poki requirements for thumbnails</SubTitle>
				<ContentParagraph large>
					When releasing your game through Poki, there are some specifics you can take into consideration. Since we use thumbnails in different sizes. And of course we want to make your thumbnail look good in the Poki visual design.
				</ContentParagraph>

				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Size matters</CalloutTitle>
						<ContentParagraph large>
							Design for small and big game tile sizes: We know it can be tempting to work on a big canvas and show lots of detail. But keep in mind that a lot of detail can look messy in smaller tiles. If you choose to include your game’s title in the thumbnail, <strong>make sure the typography remains legible in smaller sizes as well</strong>. Notice how the typography becomes unreadable in smaller sizes in this example.
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="thumbnail sizes" src={getCDNImage({ src: '/images/guide/thumbnail-size.png', width: 800 })} />
					</div>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Color and contrast</CalloutTitle>
						<ContentParagraph large>
							While creating a colorful thumbnail (or not colorful at all, depending on your game art style) it’s good to keep in mind that some colors are naturally contrasting, and some colors might not register as all that different. Contrast makes things “pop” visually, whereas similar shades might make a thumbnail appear to fall flat. Also keep in mind that the Poki Playground background color is #83FFE7 so make sure your thumbnail is not too similar in color as it might make your thumbnail disappear.
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="contrasted thumbnails" src={getCDNImage({ src: '/images/guide/thumbnail-contrast.png', width: 800 })} />
					</div>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Don’t cut corners</CalloutTitle>
						<ContentParagraph large>
							Deliver your thumbnail as a full-bleed square image of <strong>at least 628px by 628px</strong>. We’ll add the rounded corners as an image mask on our end.
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="thumbnail with cut corners" src={getCDNImage({ src: '/images/guide/thumbnail-corners.png', width: 800 })} />
					</div>
				</CalloutRow>
			</Main>
			<Navigation
				previousLink="/guide"
				nextLink="/guide/game-dev-tools"
				nextLabel="Poki game development tools"
			/>
			<SignUpCTA />
			<MarketingFooter />
		</Page>
	</Container>
);

export default GuideThumbnailPage;
