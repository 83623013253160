import 'moment-timezone';
import moment from 'moment';
import { keyframes, css } from 'styled-components';

import colors from 'shared/designTokens/colors.json';

export const clickhouseDataCacheSeconds = 10 * 60; // 10 minutes

export const requiredPlaytestRecordingsWatched = 5;

export const domain = `${window.location.protocol}//${window.location.host}`;
export const isMobile = (() => {
	// Same as: https://support.cloudflare.com/hc/en-us/articles/229373388-Cache-Content-by-Device-Type-Mobile-Tablet-Desktop-
	const mobile = /(?:phone|windows\s+phone|ipod|blackberry|(?:android|bb\d+|meego|silk|googlebot) .+? mobile|palm|windows\s+ce|opera mini|avantgo|mobilesafari|docomo|kaios)/i;
	const tablet = /(?:ipad|playbook|(?:android|bb\d+|meego|silk)(?! .+? mobile))/i;

	return (!tablet.test(navigator.userAgent) && mobile.test(navigator.userAgent));
})();

export const lightColors = {
	...colors,

	// These colors are missing in the design system
	green7: '#CBFDFD',
	green9: '#E8FFFA',

	rose5: '#FB87AD',
	rose7: '#FFDFE9',
	rose9: '#FFF3F7',

	yellow7: '#FFFBCD',
	yellow9: '#FEFBE0',

	purple7: '#F3E0FD',
	purple9: '#FBF3FE',

	dataBlue1: '#D4EFFF',
	dataBlue2: '#ABD2EE',
	dataBlue3: '#83B8DD',
	dataBlue4: '#5E9DCC',
	dataBlue5: '#3684BC',

	dataGreen1: '#D2F3DE',
	dataGreen2: '#AEE9C3',
	dataGreen3: '#61D291',
	dataGreen4: '#31C67A',
	dataGreen5: '#4EAC7C',

	dataPurple1: '#F6E6FF',
	dataPurple3: '#B98AE0',
	dataPurple4: '#9861D1',
	dataPurple5: '#753BC3',

	dataRed1: '#FFE1DF',
	dataRed2: '#FFB9B5',
	dataRed3: '#FF918E',
	dataRed4: '#FF696B',
	dataRed5: '#F83C4B',

	dataOrange1: '#FFE5D2',
	dataOrange2: '#FFCCA8',
	dataOrange3: '#FFB481',
	dataOrange4: '#FF9B5E',
	dataOrange5: '#FF823D',

	dataYellow1: '#F8EACD',
	dataYellow2: '#FDE3A9',
	dataYellow3: '#FFDD85',
	dataYellow4: '#FFD763',
	dataYellow5: '#FFD03D',
	dataYellow7: '#FFFBCD',

	dataGrey1: '#DEE1E5',
	dataGrey3: '#ADB5BD',
	dataGrey4: '#5D6B84',
	dataGrey5: '#495057',
	dataGrey7: '#EEF3FD',
	dataGrey8: '#F9FBFF',

	boxShadowSmall: '0px 4px 8px 0px rgba(9, 30, 66, 0.07), 0px 0px 2px 0px rgba(9, 30, 66, 0.07)',
	boxShadowMedium: '0px 16px 32px 0px rgba(9, 30, 66, 0.07), 0px 0px 4px 0px rgba(9, 30, 66, 0.07), 0px 4px 8px 0px rgba(9, 30, 66, 0.07)',
	boxShadowLarge: '0px 16px 16px 0px rgba(9, 30, 66, 0.07), 0px 0px 8px 0px rgba(9, 30, 66, 0.07), 0px 16px 32px 0px rgba(9, 30, 66, 0.07)',
};

export const darkColors = {
	...lightColors,

	denimBlue: '#ffffff',

	grey1: '#C9CCDC',
	grey3: '#B4B9CE',
	grey5: '#5C658E',
	grey7: '#484F6F',
	grey8: '#F9FBFF',
	grey9: '#1E2038',

	pureWhite: '#343950',

	boxShadowSmall: '0px 4px 8px 0px rgba(150, 190, 255, 0.1), 0px 0px 2px 0px rgba(150, 190, 255, 0.6)',
	boxShadowMedium: '0px 16px 32px 0px rgba(150, 190, 255, 0.1), 0px 0px 4px 0px rgba(150, 190, 255, 0.1), 0px 4px 8px 0px rgba(150, 190, 255, 0.1)',
	boxShadowLarge: '0px 16px 16px 0px rgba(150, 190, 255, 0.1), 0px 0px 8px 0px rgba(150, 190, 255, 0.1), 0px 16px 32px 0px rgba(150, 190, 255, 0.1)',

	purple6: '#C977E8',
	purple7: '#F3E0FD',
	purple8: '#5F2EB1',
	purple9: '#5F2EB1',
};

export const headerHeight = isMobile ? '60px' : '0px';
export const navigationWidth = 272;
export const largePadding = 48;
export const mediumPadding = 24;
export const smallPadding = 16;
export const minWidth = isMobile ? 'auto' : '1000px';
export const maxWidth = isMobile ? 'auto' : '1536px';
export const tabletWidth = 1024;

export const _ultraTiny = '@media (min-width: 350px)';
export const _tiny = '@media (min-width: 400px)';
export const _small = '@media (min-width: 720px)';
export const _medium = '@media (min-width: 992px)';
export const _large = '@media (min-width: 1367px)';
export const _giant = '@media (min-width: 1600px)';

export const motionStandard = 'cubic-bezier(0.5, 0, 0.1, 1)';
export const motionIn = 'cubic-bezier(0.25, 0, 1, 1)';
export const motionOut = 'cubic-bezier(0, 0, 0.25, 1)';

export const motionSpeed01 = '90ms';
export const motionSpeed02 = '180ms';

export const PLAYTEST_RECORDINGS = 10;

const loadingPulseKeyframes = keyframes`
	0% {
		opacity: 0.2;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.2;
	}
`;
export const loadingPulseAnimation = css`${loadingPulseKeyframes} 1.5s infinite both`;

export const customScrollbar = css`
	&::-webkit-scrollbar {
		width: 6px;
	}

	& {
		scrollbar-width: thin;
		scrollbar-color: ${props => props.theme.grey7} transparent;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${props => props.theme.grey7};
		border-radius: 2px;
		border: 3px solid transparent;
	}
`;

export const dayMonthYearFormat = 'D MMM YYYY';
export const dayMonthYearTimeFormat = 'D MMM YYYY, HH:mm';
export const dayMonthTimeFormat = 'D MMM, HH:mm';

export const earliestMomentInP4D = moment('2019-11-01');

// This will go wrong if you keep the page open during Daylight saving time.
// But this is such a minor issue that it's not worth the effort to fix it.
export const utcOffset = moment().tz('Europe/Amsterdam').utcOffset() / 60;

export const activeMonthsInP4D = (() => {
	const result = [];

	for (let i = Math.ceil(earliestMomentInP4D.diff(moment(), 'months', true)); i < 1; i++) {
		result.push(earliestMomentInP4D.clone().subtract(i, 'month').startOf('month'));
	}

	return result;
})();

export const apiDone = { done: true, pending: false, error: false };

export const SPECIAL_OPERATORS = {
	IN_SET: (field, values) => [field, 'IN_SET', values],
	LIKE: (field, value) => [field, 'LIKE', `%${value}%`],
};

export const STANDARD_REVENUE_SHARE = {
	ingame_poki_promotion: 50,
	ingame_organic_landing: 100,
	developer_domain: 80,
	external: 80,
	poki_platform_display: 0,
};

export const userRoles = [
	{
		value: 'developer',
		desc: 'Developer',
	},
	{
		value: 'developer-support',
		desc: 'Developer Support',
	},
];

// Must be updated when adding new flags to the mothership
export const teamFlags = [
	{
		value: '',
		desc: 'No flags',
		sortValue: 7,
	},
	{
		value: 'activate_approved_versions',
		desc: 'Activate approved versions',
		sortValue: 5,
	},

	{
		value: 'activate_external_versions',
		desc: 'Activate external versions',
		sortValue: 4,
	},
	{
		value: 'allow_set_versions_active',
		desc: 'Allow set versions active',
		sortValue: 3,
	},
	{
		value: 'temporarily_locked_out',
		desc: 'Temporarily locked out',
		sortValue: 1,
	},
	{
		value: 'must_update_billing',
		desc: 'Must update billing',
		sortValue: 0,
	},
	{
		value: 'can_see_game_events',
		desc: 'Can see Game Events page',
		sortValue: 6,
	},
];

export const teamCustomerSegments = [
	{
		value: 'hit-studio',
		desc: 'Hit Studio',
	},
	{
		value: 'hobbyist',
		desc: 'Hobbyist',
	},
	{
		value: 'pro-creator',
		desc: 'Pro Creator',
	},
	{
		value: 'pro-studio',
		desc: 'Pro Studio',
	},
	{
		value: 'top-creator',
		desc: 'Top Creator',
	},
	{
		value: 'test-team',
		desc: 'Test Team',
	},
	{
		value: 'self-service',
		desc: 'Self Service',
	},
	{
		value: 'game-jam',
		desc: 'Game Jam',
	},
];

export const easings = {
	bounce: 'linear(0, 0.004, 0.016, 0.035, 0.063, 0.098, 0.141 13.6%, 0.25, 0.391, 0.563, 0.765, 1, 0.891 40.9%, 0.848, 0.813, 0.785, 0.766, 0.754, 0.75, 0.754, 0.766, 0.785, 0.813, 0.848, 0.891 68.2%, 1 72.7%, 0.973, 0.953, 0.941, 0.938, 0.941, 0.953, 0.973, 1, 0.988, 0.984, 0.988, 1)',
	figma: 'linear(0, 0.007, 0.029 2.2%, 0.119 4.8%, 0.63 14.5%, 0.832 19.1%, 0.908, 0.968, 1.014, 1.047 28.7%, 1.06 30.3%, 1.07, 1.075 33.9% 35.9%, 1.065 40.4%, 1.016 53.3%, 0.999 61.5%, 0.994 71%, 1)',
};

export const engines = [
	'away-js',
	'babylon-js',
	'booty5',
	'cocos2d',
	'construct-2',
	'construct-3',
	'create-js',
	'defold',
	'fancade',
	'flash',
	'gamemaker',
	'gdevelop',
	'gideros',
	'godot',
	'haxe',
	'haxeflixel',
	'impact-js',
	'layabox',
	'native-javascript',
	'phaser',
	'pixi-js',
	'playcanvas',
	'ruffle',
	'solar2d',
	'stencyl',
	'three-js',
	'tingly',
	'unity',
	'other',
];

export const clipPathTop = `polygon(
	0 2.5vw,
	70% 0%,
	calc(70% + 6vw) 2.5vw,
	100% 1.2vw,
	100% 100%,
	0 100%
);
`;

export const clipPathBottom = `polygon(
	0 0,
	100% 0,
	100% calc(100% - 2.5vw),
	calc(30% + 6vw) 100%,
	30% calc(100% - 2.5vw),
	0 calc(100% - 1.2vw)
);
`;
