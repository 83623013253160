import React from 'react';
import { Helmet } from 'react-helmet';

import { Container, BackgroundImage, Page } from 'site/src/components/styled';
import MarketingHeader from 'site/src/components/MarketingHeader';
import ArrowLeftIcon from 'shared/designTokens/icons/ui/small/ArrowLeftIcon';
import { ParentDirectory, CalloutRow, CallOutColumn, Main, Navigation, Title, CalloutTitle, StyledList, SubTitle, ContentParagraph, IframeContainer, StyledIframe } from 'site/src/components/guide.styled';
import SignUpCTA from 'site/src/components/SignUpCTA';
import MarketingFooter from 'shared/components/MarketingFooter';
import getCDNImage from 'site/src/utils/getCDNImage';

const GuideLocalizationPage = () => (
	<Container>
		<Helmet key="GuideContentPage">
			<title>Poki for Developers - Web game guide</title>
		</Helmet>
		<BackgroundImage center backgroundUrl="/images/header_heart.svg" />
		<MarketingHeader />
		<Page>
			<ParentDirectory href="/guide"><ArrowLeftIcon />Overview</ParentDirectory>
			<Main>
				<Title>Localization</Title>
				<ContentParagraph extraLarge>
					The more languages you have available in your game, the more people will be able to play and enjoy it. Localization will improve engagement for players as they will be able to understand what is happening in-game, especially for any game that has text. For the Poki audience, as we are worldwide, non-localized text based games struggle in countries not localized so it’s really important to think this through when creating your game!
				</ContentParagraph>
				<SubTitle>Prepare your game for localization</SubTitle>
				<ContentParagraph large>
					Localization can be time consuming and expensive if you haven’t prepared your game for it e.g. if text blocks are not all kept in the same .json file, it can be hard and tedious to find all text in the game and translate it one-by-one.
				</ContentParagraph>

				<ContentParagraph large>
					<strong>Best genres for localization:</strong>
				</ContentParagraph>

				<StyledList large>
					<li>Story games</li>
					<li>Clicker and Idle games</li>
					<li>Quiz Games</li>
					<li>Any <strong>text-based</strong> game, or a game has <strong>features that are explained through text</strong> (guns, mechanics, powerups etc.)</li>
				</StyledList>

				<SubTitle>Language groups to translate into</SubTitle>

				<StyledList large>
					<li><strong>EFIGS</strong> (English, French, Italian, German, Spanish). This should be first to cover a broad audience in Europe, North America, and parts of South America.</li>
					<li><strong>CJK</strong> (Simplified Chinese, Japanese, Korean). This is second as the Asian gaming market is huge, but the languages are more time-consuming and expensive to translate into - so only do this if you are confident it will be worth it.</li>
					<li><strong>Brazilian-Portugese and Russian</strong> are last to cover the rest of your basis around the world.</li>
					<li>We also recommend Turkish as it is an increasingly popular locale, and it would be a good idea to add that with the first batch.</li>
				</StyledList>

				<ContentParagraph large>
					You will have covered all the main languages in the world with these 10 languages, so you should have no issue with reaching a big audience. Here’s a good video from YouTuber AuroDev on choosing languages to translate your game into.
				</ContentParagraph>

				<IframeContainer>
					<StyledIframe src="https://www.youtube.com/embed/BscAkqhYBdc" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
				</IframeContainer>

				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Tip: How to display languages</CalloutTitle>
						<StyledList large>
							<li>It can be as simple as a toggle in the menu like this - but keep in mind that the more languages, the better. Poki has millions of players from all over the world, so make sure you cover as many bases as possible. 💪</li>
							<li>We suggest serving the game in the player’s browser language with a behind-the-scenes check.</li>
						</StyledList>
					</CallOutColumn>
					<div>
						<img alt="loading example" src={getCDNImage({ src: '/images/guide/localization-example.png', width: 800 })} />
					</div>
				</CalloutRow>
			</Main>
			<Navigation
				nextLink="/guide/monetization"
				nextLabel="Monetization"
			/>
			<SignUpCTA />
			<MarketingFooter />
		</Page>
	</Container>
);

export default GuideLocalizationPage;
