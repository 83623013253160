import React, { useState, useEffect, useRef } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { useSelectIsPersistedHiddenMessageBox } from 'app/src/selectors/session';
import { persistHideMessageBox } from 'app/src/actions/session';

import Button from 'app/src/components/ui/Button';
import IconButton from 'app/src/components/ui/IconButton';

import CloseIcon from 'shared/designTokens/icons/ui/small/CloseIcon';
import ChevronDownIcon from 'shared/designTokens/icons/ui/medium/ChevronDownIcon';
import { isMobile } from 'shared/vars';
import InfoPoint from './InfoPoint';

const Buttons = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: -14px;
	margin-left: auto;

	> * {
		margin: 12px 8px 8px 0;
	}

	@media (max-width: 550px) {
		justify-content: flex-start;

		> * {
			margin-left: 0;
			margin-bottom: 0;
		}
	}

	fieldset {
		margin-bottom: 0;
	}
`;

const CardContainer = styled.div`
	width: 100%;
	position: relative;
	background: ${props => props.theme.pureWhite};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	box-shadow: ${props => props.theme.boxShadowSmall};
	min-width: 0;

	& & {
		box-shadow: none;
		border: 1px solid ${props => props.theme.grey7};
	}

	${props => props.elevated // re-force box shadow if we're elevated
	&& `
	&&& {
		box-shadow: ${props.theme.boxShadowSmall};
		border: none;
		z-index: 3;

		&:hover {
			z-index: 4;
		}
	}
	`}

	${props => props.hasDescription && `
	${Buttons} {
		margin: 0;
		margin-left: auto;
	}
	`}

	p {
		font-size: 16px;
		line-height: 24px;
		color: ${props => props.theme.grey3};
	}

	p + h3,
	p + & {
		margin-top: 54px;
	}

	h1 + p,
	h2 + p,
	h3 + p,
	h4 + p,
	h5 + p {
		margin-top: -16px;
	}
`;

const Title = styled.h3`
	font-size: 21px;
	margin: 0;
	display: flex;

	${props => props.expandPreview && `
	color: ${props.theme.pokiBlue};
	`}
`;

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: ${props => (props.noPadding ? '0' : '24px')};

	@media (max-width: 550px) {
		padding: ${props => (props.noPadding ? '0' : '18px')};
	}

	p:first-child {
		margin-top: 0;
	}
`;

const Head = styled.div`
	display: flex;
	padding: 24px;
	align-items: flex-start;

	@media (max-width: 550px) {
		padding: 18px;
		flex-wrap: nowrap;
	}

	${props => props.expandable && `
	cursor: pointer;

	&:hover {
		background: ${props.theme.grey9};
	}
	`}

	& + ${InnerContainer} {
		padding-top: 0;
	}
`;

const Left = styled.div`
	display: flex;
	flex-direction: column;
`;

const Description = styled.div`
	font-size: 16px;
	font-family: Proxima Nova;
	color: ${props => props.theme.grey3};
	margin-top: 2px;
`;

const ToggleChevronDownIcon = styled(ChevronDownIcon)`
	margin: -6px;
	margin-right: 6px;

	path {
		fill: ${props => props.theme.grey5};
	}

	${props => props.$flip && `
	transform: scaleY(-1);
	`}
`;

const CancelButton = styled.div`
	background-color: ${props => props.theme.grey7};
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 36px;
    height: 36px;
	cursor: pointer;
	flex-shrink: 0;
	margin: -6px 0 -6px auto;

	[fill] {
		fill: ${props => props.theme.grey3};
	}

	&:hover {
		&& {
			background-color: ${props => props.theme.pokiBlue};
		}

		[fill] {
			fill: ${props => props.theme.pureWhite};
		}
	}
`;

const StyledIconButton = styled(IconButton)`
	background: ${props => props.theme.pokiBlue};
	
	svg [fill] {
		fill: ${props => props.theme.pureWhite};
	}
`;

const renderButtons = button => {
	const { id, title, type, action, disabled = false, children, icon, secondary = false, positive = false, negative = false, warning } = button;

	switch (type) {
		case 'button':
			if (isMobile && icon) {
				return (
					<StyledIconButton key={id} onClick={action} disabled={disabled} icon={icon} />
				);
			}

			return (
				<Button
					key={id}
					title={title}
					icon={icon}
					onClick={action}
					disabled={disabled}
					secondary={secondary}
					positive={positive}
					negative={negative}
				>
					{children}
				</Button>
			);
		case 'icon-button':
			return (
				<IconButton
					key={id}
					title={title}
					onClick={action}
					disabled={disabled}
					icon={icon}
				/>
			);
		case 'info':
			return (
				<InfoPoint disabled={disabled} icon={icon} warning={warning}>
					{children}
				</InfoPoint>
			);
		case 'custom':
			return (
				<React.Fragment key={id}>
					{children}
				</React.Fragment>
			);
		default:
			return null;
	}
};

const Card = props => {
	const { className, elevated, title, description, children, buttons, noPadding, expandable = false, startExpanded = false, onToggleExpand, closeable = false, id = '' } = props;

	const dispatch = useDispatch();

	const buttonsRef = useRef();
	const [active, setActive] = useState(!!startExpanded);

	const [isHidden, setIsHidden] = useState(id && useSelectIsPersistedHiddenMessageBox(id));

	const handleHide = () => {
		dispatch(persistHideMessageBox({ id }));

		setIsHidden(true);
	};

	useEffect(() => {
		if (onToggleExpand) {
			onToggleExpand(active);
		}
	}, [active]);

	const attemptSetActive = e => {
		if (!expandable || buttonsRef?.current?.contains(e.target)) return;

		setActive(a => !a);
	};

	return !isHidden && (
		<CardContainer className={className} hasDescription={description} elevated={elevated}>
			{(title || description || buttons) && (
				<Head
					expandable={expandable}
					onClick={attemptSetActive}
				>
					<Left>
						<Title>
							{expandable && <ToggleChevronDownIcon $flip={!active} />}
							{title}
						</Title>
						{description && <Description>{description}</Description>}
					</Left>
					{buttons && (
						<Buttons ref={buttonsRef}>
							{buttons.map(button => renderButtons(button))}
						</Buttons>
					)}
					{closeable && (
						<CancelButton onClick={handleHide}>
							<CloseIcon />
						</CancelButton>
					)}
				</Head>
			)}
			<InnerContainer noPadding={noPadding}>
				{(!expandable || active) && children}
			</InnerContainer>

		</CardContainer>
	);
};

Card.propTypes = {
	className: PT.string,
	elevated: PT.bool,
	title: PT.string,
	description: PT.string,
	children: PT.node,
	buttons: PT.node,
	noPadding: PT.bool,
};

Card.defaultProps = {
	className: null,
	elevated: false,
	title: null,
	description: null,
	children: null,
	buttons: null,
	noPadding: false,
};

export default Card;
