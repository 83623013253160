import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { Container, BackgroundImage, Page } from 'site/src/components/styled';
import MarketingHeader from 'site/src/components/MarketingHeader';
import ArrowLeftIcon from 'shared/designTokens/icons/ui/small/ArrowLeftIcon';
import { ParentDirectory, Callout, CalloutRow, CallOutColumn, Main, Navigation, Title, CalloutTitle, StyledList, SubTitle, ContentParagraph } from 'site/src/components/guide.styled';
import SignUpCTA from 'site/src/components/SignUpCTA';
import MarketingFooter from 'shared/components/MarketingFooter';
import getCDNImage from 'site/src/utils/getCDNImage';

const Row = styled.div`
	display: flex;

	img {
		max-width: calc(100% / 3);
		height: auto;
		object-fit: contain;
	}
`;

const GuideMonetizationPage = () => (
	<Container>
		<Helmet key="GuideContentPage">
			<title>Poki for Developers - Web game guide</title>
		</Helmet>
		<BackgroundImage center backgroundUrl="/images/header_heart.svg" />
		<MarketingHeader />
		<Page>
			<ParentDirectory href="/guide"><ArrowLeftIcon />Overview</ParentDirectory>
			<Main>
				<Title>Monetization</Title>
				<ContentParagraph extraLarge>
					The earlier you think about monetization for you game, the better. Leaving it until the last moment makes it feel forced and often not worthwhile for players compared to implementing it early. Doing this means you can make ads feel really beneficial by offering very relevant rewards at the relevant time. This will help boost the monetization and revenue for you game without impacting UX as negatively!
				</ContentParagraph>
				<SubTitle>What are Rewarded Videos?</SubTitle>
				<ContentParagraph large>
					Rewarded Videos allow players to choose to <strong>watch a video ad in exchange for a certain benefit in a game</strong>. They are meant to enhance the player experience, and should never block content of the core gameplay.
				</ContentParagraph>
				<ContentParagraph large>
					In this page you can find the basics of Rewarded Videos as well as some ways you might be able to integrate them into your game 💪
				</ContentParagraph>
				<ContentParagraph large>
					Disclaimer: Prioritize fun! If players aren’t engaged with your game, the different Rewarded Video options won’t make a big difference. We know that games with a higher engagement also get more clicks on Rewarded Videos 📈.
				</ContentParagraph>

				<Callout>
					<CalloutTitle>Requirements</CalloutTitle>
					<StyledList large>
						<li>Rewarded Videos <strong>should never block content of the core gameplay</strong>, but rather serve as an extra optional perk.</li>
						<li>Any button that triggers a Rewarded Video should be <strong>clearly distinguishable</strong> (with text and/or an icon), accessible, and not misleading in any way.</li>
					</StyledList>
				</Callout>

				<SubTitle>Types of Rewarded Videos</SubTitle>
				<ContentParagraph large>
					In order to better understand how games could benefit from Rewarded Videos, we have divided them into several categories.
				</ContentParagraph>

				<ContentParagraph large>
					Keep in mind that the examples below are some of the most used options, which doesn’t mean you should stop experimenting or come up with brand new cool ideas! 💪
				</ContentParagraph>

				<SubTitle>🤝 A Helping Hand</SubTitle>

				<ContentParagraph large>
					Rewarded Videos can be helpful when trying to balance difficulty in a game (which plays a big part in engagement), since it provides players with alternative ways to reach their goals.
				</ContentParagraph>
				<ContentParagraph large>
					While all games should be accessible and easy to understand, offering a helping hand to those players who are struggling will help reduce the number of drop-offs.
				</ContentParagraph>

				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Revives</CalloutTitle>
						<ContentParagraph large>
							Watch a Rewarded Video to revive/go back to the game (without losing progress).
						</ContentParagraph>
						<ContentParagraph large>
							Example: <a href="https://poki.com/en/g/subway-surfers">Subway Surfers</a>
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="revive example" src={getCDNImage({ src: '/images/guide/monetization-revive.png', width: 800 })} />
					</div>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Level skip</CalloutTitle>
						<ContentParagraph large>
							Watch a Rewarded Video to skip the current level and move forward.
						</ContentParagraph>
						<ContentParagraph large>
							Example: <a href="https://poki.com/en/g/drive-mad">Drive Mad</a>
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="skip example" src={getCDNImage({ src: '/images/guide/monetization-skip.png', width: 800 })} />
					</div>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Hints</CalloutTitle>
						<ContentParagraph large>
							Watch a Rewarded Video to get a hint in the current level (to help players when they get stuck).
						</ContentParagraph>
						<ContentParagraph large>
							Example: <a href="https://poki.com/en/g/infinity-loop">Infinity Loop</a>
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="hint example" src={getCDNImage({ src: '/images/guide/monetization-hint.png', width: 800 })} />
					</div>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Stat boost</CalloutTitle>
						<ContentParagraph large>
							Watch a Rewarded Video to get improvements in stats (like health, damage, mana, stamina, shield, etc.).
						</ContentParagraph>
						<ContentParagraph large>
							Example: <a href="https://poki.com/en/g/gladihoppers">Gladihoppers</a>
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="boost example" src={getCDNImage({ src: '/images/guide/monetization-boost.png', width: 800 })} />
					</div>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Speed up the game</CalloutTitle>
						<ContentParagraph large>
							Watch a Rewarded Video to speed up the whole game or a specific skill/action.
						</ContentParagraph>
						<ContentParagraph large>
							Example: <a href="https://poki.com/en/g/merge-round-racers">Merge Round Racers</a>
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="speed up example" src={getCDNImage({ src: '/images/guide/monetization-speed-up.png', width: 800 })} />
					</div>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Extra ability</CalloutTitle>
						<ContentParagraph large>
							Watch a Rewarded Video to gain a new or special ability/item.
						</ContentParagraph>
						<ContentParagraph large>
							Example: <a href="https://poki.com/en/g/blumgi-castle">Blumgi Castle</a>
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="extra ability example" src={getCDNImage({ src: '/images/guide/monetization-ability.png', width: 800 })} />
					</div>
				</CalloutRow>

				<SubTitle>💰 In-Game Economy</SubTitle>

				<ContentParagraph large>
					Both Rewarded Videos and in-game economy systems (coins, points, keys, tokens…) often coexist within the same game. In a lot of cases the content available through rewarded videos can also be available through the game’s internal economy.
				</ContentParagraph>

				<ContentParagraph large>
					Letting users decide if they want to get a reward using the coins they have collected by playing, or if they prefer to watch a video, is a good strategy to keep a balance between different game mechanics.
				</ContentParagraph>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Multiply reward</CalloutTitle>
						<ContentParagraph large>
							Watch a Rewarded Video to multiply (for example x2) the coins/points that you’ve gotten (either during gameplay or as offline earnings).
						</ContentParagraph>
						<ContentParagraph large>
							Example: <a href="https://poki.com/en/g/beecoins-inc">Beecoins Inc</a>
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="multiply reward example" src={getCDNImage({ src: '/images/guide/monetization-multiply-reward.png', width: 800 })} />
					</div>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Direct coin unlocks</CalloutTitle>
						<ContentParagraph large>
							Watch a Rewarded Video to unlock a specific amount of coins/points.
						</ContentParagraph>
						<ContentParagraph large>
							Example: <a href="https://poki.com/en/g/brain-test-tricky-puzzles">Brain Test: Tricky Puzzles</a>
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="coin unlock example" src={getCDNImage({ src: '/images/guide/monetization-coin-unlock.png', width: 800 })} />
					</div>
				</CalloutRow>

				<SubTitle>🎀 Customization</SubTitle>

				<ContentParagraph large>
					Giving players the opportunity to customize aspects of the game helps them create a bigger sense of ownership, investment, and connection to it. Unlocking a collection of items also serves as a new goal for players, and makes the game more re-playable.
				</ContentParagraph>

				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>Feature Unlock</CalloutTitle>
						<ContentParagraph large>
							Watch a Rewarded Video to get a new feature that enhances the game experience, such as environment changes, music/songs, a new color scheme for the UI…
						</ContentParagraph>
						<ContentParagraph large>
							Example: <a href="https://poki.com/en/g/numbers">Numbers</a>
						</ContentParagraph>
					</CallOutColumn>
					<div>
						<img alt="feature unlock example" src={getCDNImage({ src: '/images/guide/monetization-unlock.png', width: 800 })} />
					</div>
				</CalloutRow>
				<Callout>
					<CalloutTitle>Cosmetics</CalloutTitle>
					<ContentParagraph large>
						Watch a Rewarded Video to get one or more cosmetic aspects for your character/weapon. This can be done through a direct unlock (1 video = 1 reward), or in a progressive way (several videos = 1 reward).
					</ContentParagraph>
					<ContentParagraph large>
						Example: <a href="https://poki.com/en/g/stickman-hook">Stickman Hook</a>
					</ContentParagraph>
					<Row>
						<img alt="cosmetic example" src={getCDNImage({ src: '/images/guide/monetization-cosmetic-1.png', width: 500 })} />
						<img alt="cosmetic example" src={getCDNImage({ src: '/images/guide/monetization-cosmetic-2.png', width: 500 })} />
						<img alt="cosmetic example" src={getCDNImage({ src: '/images/guide/monetization-cosmetic-3.png', width: 500 })} />
					</Row>
				</Callout>

				<SubTitle>Best Practices/Tips</SubTitle>
				<StyledList large>
					<li>We strongly advise you to show the option/button to continue the game’s natural flow (the one without a Rewarded Video behind it) on the primary and most natural position. Bringing too much attention to the Rewarded Video button or delaying the other options could hurt your players’ trust.</li>
					<li>Make sure rewarded options are not too invasive or insistent. If players feel too pushed to watch an ad or too many notifications are on their way, engagement could decrease.</li>
					<li>The industry standard to indicate a Rewarded Video is the icon 🎬.</li>
					<li>Make sure the player knows they have been rewarded after the ad. You can do this through an animation, with sound, or any kind of celebration.</li>
					<li>It’s usually a good idea to equip/activate the reward right after the player unlocks it (to minimize the amount of clicks and navigation), but this will depend on the type of reward.</li>
					<li>While letting players watch infinite Rewarded Videos is allowed, keep in mind how this could affect your game’s balance and progression, so you can set an according limit.</li>
					<li>Introducing dynamic Rewarded Video opportunities by showing Rewarded Video options only under specific circumstances (instead of static buttons), can give you more control on the amount of rewards a player is getting. For example, introducing it via an item/character that shows up only for some seconds, or modifying an earnings multiplier to make it more attractive to the player.</li>
					<li>Consider using your Rewarded Videos with temporary or themed content! Strategies like weekly or seasonal unlocks can help you increase engagement and retention.</li>
				</StyledList>
			</Main>
			<Navigation
				nextLink="/guide/thumbnail"
				nextLabel="Game thumbnail"
			/>
			<SignUpCTA />
			<MarketingFooter />
		</Page>
	</Container>
);

export default GuideMonetizationPage;
