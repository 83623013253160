import twemoji from 'twemoji';
import { LocalizationError } from 'app/src/errors';
import { utcOffset } from 'shared/vars';

const copy = {
	'10k-test-with-content-restrictions': 'Web Fit Test with content restrictions',
	'10k-test-with-cr': 'Web Fit Test (CR)',
	'10k-test': 'Web Fit Test',
	'developer-support': 'Developer Support',
	'full-release-with-content-restrictions': 'Full Release with content restrictions',
	'full-release-with-cr': 'Full Release (CR)',
	'full-release': 'Full Release',
	'game(s)': 'Game(s)',
	'global-release': 'Global Release',
	'limited-release-with-content-restrictions': 'Limited Release with content restrictions',
	'limited-release-with-cr': 'Limited Release (CR)',
	'limited-release': 'Limited Release',
	'no-link-release': 'No Link Release',
	'not-released': 'Not Released',
	'read-only-admin': 'Read-Only Admin',
	'selectGame(s)': 'Select Game(s)',
	'soft-release-with-content-restrictions': 'Soft Release with content restrictions',
	'soft-release-with-cr': 'Soft Release (CR)',
	'soft-release': 'Soft Release',
	'technical-test-with-content-restrictions': 'Technical Test with content restrictions',
	'technical-test-with-cr': 'Technical Test (CR)',
	'technical-test': 'Technical Test',
	'web-fit-test-finished': 'Web Fit Test Finished',
	accountDisabledMessageDesc: 'Don\'t worry, we\'ve probably misplaced something. Try again later or get in touch with <a href="mailto:developersupport@poki.com">developersupport@poki.com</a>.',
	accountDisabledMessageTitle: 'Looks like we can\'t access your account at the moment.',
	action: 'Action',
	actions: 'Actions',
	active: 'Active',
	activeVersion: 'Active version',
	adblockerDetected: 'Adblocker detected',
	addFilters: 'Add Filters',
	addGame: 'Add Game',
	additionalPayments: 'Additional Payments',
	additionalPaymentsRevenueSharesRequired: 'Set Revenue Shares First',
	additionalPaymentsRevenueSharesRequiredDescription: 'This team must have active revenue shares before you can add this additional payment.',
	additionalPaymentsRevenueSharesRequiredOptionDescription: 'This team must have active revenue shares before you can select this option.',
	addMinimumGuarantee: 'Add Monthly Minimum Guarantee',
	addMinimumGuaranteeDescription: 'With this Monthly MG, we guarantee that we will pay a certain amount to a developer in a month. If their revenue exceeds that amount, we pay that but if not, we pay the Monthly MG.',
	addMinimumGuaranteeShort: 'Add Monthly MG',
	addNew: 'Add New',
	addNewDomainCollection: 'Add New Domain Collection',
	addNewGameEngine: 'Add new game engine',
	addNewTeam: 'Add New Team',
	addOneOffPayment: 'Add One-Off Payment',
	addOneOffPaymentDescription: 'Add a one-off payment to a developer team for license fee(s), refer-a-friend, or server or production costs.',
	addRecurringMonthlyPayment: 'Add Recurring Monthly Payment',
	addRecurringMonthlyPaymentDescription: 'With this Recurring Monthly Payment, we add a certain amount to a developer\'s invoice each month.',
	addRecurringMonthlyPaymentShort: 'Add Recurring Monthly Payment',
	addStudioDetails: 'Add your studio details',
	addUpfrontPayment: 'Add Upfront Payment',
	addUpfrontPaymentDescription: 'This will trigger a one-off, recoupable payment from us to the developer. We will not pay them again until this money has been made back from the game(s) specified.',
	addYourGame: 'Add your game',
	adImpressions: 'Ad Impressions',
	admin: 'Admin',
	adminOnly: 'Admin only',
	adminSettings: 'Admin Settings',
	adsPerDAU: 'Ads per DAU',
	adTime: 'Ad Time',
	adType: 'Ad Type',
	advancedOptions: 'Advanced options',
	advancedRevenueShareSettings: 'Advanced Revenue Sharing Settings',
	advancedRevenueShareSettingsDesc: 'Setting a specific revenue share per game overrides the above settings of the team level.',
	afterUploadShare: 'After uploading your game you can already share it with friends. Let\'s upload a game first.',
	all: 'All',
	allEarnings: 'All earnings this month',
	allowOnMobile: 'Allow on mobile',
	allowOnMobileEverywhere: 'Allow on mobile everywhere',
	alreadyHaveAccount: 'If you already have an account',
	amount: 'Amount',
	apply: 'Apply',
	approve: 'Approve',
	approved: 'Approved',
	approveGame: 'Approve Game',
	approveGameDescription: 'Approved games can request reviews on versions and see a normal game dashboard.',
	approveGameTitle: 'Are you sure you want approve this game?',
	approveInvoice: 'Approve Invoice',
	archive: 'Archive',
	archivedVersions: 'Archived versions',
	avgDailyActiveUsers: 'Avg Daily Active Users',
	back: 'Back',
	bankAccountName: 'Bank Account Name',
	bankAccountNumber: 'IBAN / Bank Account Number',
	bankBIC: 'BIC / SWIFT',
	bankName: 'Bank Name',
	beaconSnippet: 'Beacon Snippet Integration Guide',
	beaconSnippetAdditionalInformation: 'Additional Information:',
	beaconSnippetDesc: 'To enhance the security of our web games and protect against unauthorized use, we encourage you to integrate our Beacon technology into your game. The Beacon is a lightweight solution designed to help us determine if the game is running on any unauthorized domains.',
	beaconSnippetHowToCall: 'Feel free to add this call multiple times, such as after loading and when starting specific levels. Validate the Beacon integration using our Inspector tool at <a rel="noreferrer" href="https://inspector.poki.dev" target="_blank">https://inspector.poki.dev.</a>',
	beaconSnippetIntegrationProcess: 'Integration Process:',
	beaconSnippetIntegrationProcessDesc: 'Simply include the following one-line snippet in your game code:',
	beaconSnippetListItem01: 'You can opt-out by simply omitting the provided snippet from your code.',
	beaconSnippetListItem02: 'There are no specific limitations on the frequency of Beacon calls, but we suggest spreading them out, aiming for 2-4 calls per session.',
	beaconSnippetListItem03: 'The Beacon technology is designed for seamless integration with no impact on game performance.',
	beaconSnippetListItem04: 'Note: The `/%%path%%` part of the URL can be customized to anything related to your game.',
	beaconSnippetReachOut: 'If you encounter any issues during implementation or have questions about the Beacon integration, please reach out to your support contact. We\'re always happy to help!',
	beingImpersonatedBy: 'Being impersonated by',
	billing: 'Billing',
	billingAddress: 'Billing Address',
	billingCountry: 'Billing Country',
	billingEmail: 'Billing Email',
	billingInformation: 'Billing Information',
	billingInformationDesc: 'Please make sure billing information is filled in and up to date if a revenue share is enabled.',
	billingName: 'Billing Name',
	billingSettingsRequired: 'Billing settings required',
	billingSettingsRequiredDesc: 'Add billing settings to this team before setting revenue shares or additional payments.',
	billingSettingsSavedSuccessfully: 'Billing settings saved successfully',
	browser: 'Browser',
	bugreport: 'Bug Report',
	buildNotSupported: 'Build not supported. Please upload a .zip file instead.',
	canAlreadyShare: 'You can already share your game with friends. Let\'s see if it works!',
	cancel: 'Cancel',
	cancelling: 'Cancelling...',
	cancelRequest: 'Cancel request',
	category: 'Category',
	ccsp: 'Custom Content Security Policy (please ask Casper or ErikD to do this)',
	changeRevenueShare: 'Change Revenue Share',
	changesRequested: 'Changes requested',
	changeThumbnail: 'Change Thumbnail',
	channel: 'Channel',
	checkYourEmailDescription: 'We\'ve sent an email to <strong>%%email%%</strong> that contains a magic sign in link. You can use this to access your account.<br /><br />Please note, this link is only active for 30 minutes.',
	checkYourEmailTitle: 'Check Your Email',
	checkYourEmailTrouble: 'Having trouble signing in? <br /><a href="mailto:developersupport@poki.com">Contact Us</a> and we can help.',
	clickToSetThumbnail: 'Click to set thumbnail',
	close: 'Close',
	completed: 'Completed',
	completedOn: 'Completed on %%date%%',
	completedReviews: 'Completed reviews',
	completeProfile: 'Complete profile',
	confirm: 'Confirm',
	confirmApproved: 'Confirm approved',
	confirmBillingSettings: 'Confirm Billing Settings',
	confirmCancellation: 'Confirm cancellation',
	confirmChangesRequested: 'Confirm changes requested',
	confirmEmail: 'Confirm Email Address',
	confirmEmailDescription: 'You will be sent a new sign in link to this email.<br /><br />Please type <strong>%%email%%</strong> to confirm.',
	confirming: 'Please confirm (%%countdown%%)',
	confirmRemoval: 'Confirm Removal',
	confirmRemoveGame: 'Confirm Remove Game',
	confirmRemoveGameDesc: 'Are you sure you want to remove: <strong>%%gameName%%</strong>?<br /><br />Please type <strong>remove</strong> to confirm.',
	confirmRemoveRevenueShares: 'Confirm Remove Revenue Shares',
	confirmRemoveRevenueSharesDesc: 'Are you sure you want to remove all revenue shares for <strong>%%teamName%%</strong>?<br /><br />Please type <strong>remove</strong> to confirm.',
	confirmSendApprovedInvoices: 'Are you sure you want to send all approved invoices?',
	confirmSetPublicVersionNoRevShareDesc: 'No revenue share is set for this game, are you sure you want to set this version active?',
	confirmSetPublicVersionNoRevShareTitle: 'No revenue share set!',
	contractualMilestone: 'Contractual Milestone',
	copied: 'Copied!',
	copyPermalink: 'Copy Permalink',
	copyToClipboard: 'Copy to clipboard',
	country: 'Country',
	create: 'Create',
	createAccount: 'Create account',
	createdAt: 'Created at',
	createdOn: 'Created on',
	createGame: 'Create Game',
	createReport: 'Create report',
	createUser: 'Create User',
	createVersion: 'Create Version',
	creating: 'Creating...',
	crosspromoAds: 'Crosspromo Ads',
	crosspromoAdsDesc: 'Ads that are triggered in-game when the previous game was from the same developer.',
	crosspromoAdsShort: 'Crosspromo',
	currency: 'Currency',
	currentlyPlaying: 'Currently playing',
	custom: 'Custom',
	customAllowListDescription: 'This game is playable on a specific set of domain collections.',
	customDomainAllowList: 'Custom Domain Allow List',
	customerSegment: 'Customer Segment',
	dailyActiveUsers: 'Daily Active Users',
	dailyPlayingUsers: 'Daily Playing Users',
	darkMode: 'Dark Mode',
	dashboard: 'Dashboard',
	dashboardEmptyStateDesc: 'Select a game from the menu to upload, test, and submit a new game version.',
	dataLastUpdatedXAgo: `Data last updated %%timeSince%% ago (GMT +${utcOffset})`,
	dataOnlyAvailableFromX: 'Data for this chart is only available from %%availableFrom%%',
	date: 'Date',
	dateRange: 'Date Range',
	dateSent: 'Date Sent',
	days: '%%amount%% days',
	desktop: 'Desktop',
	details: 'Details',
	developer: 'Developer',
	developerDomain: 'Developer Domain',
	developerGuide: 'Developer Guide',
	developerNotes: 'Developer notes',
	device: 'Device',
	deviceAndBrowserBreakdown: 'Device and browser breakdown',
	devicePixelRatio: 'devicePixelRatio',
	discardChanges: 'Discard Changes',
	discordNotVerified: 'Please verify your Discord account and try again.',
	domain: 'Domain',
	domainAllowList: 'Domain Allow List',
	domainAllowListDescription: 'Configure where this game can be embedded through games.poki.com',
	domainCollection: 'Domain Collection',
	domainCollectionNameAlreadyExists: 'Domain Collection with this name already exists',
	domainCollectionNamePlaceholder: 'domain-collection-name',
	domainCollections: 'Domain Collections',
	domainPlaceholder: 'domain.com',
	domains: 'Domains',
	donateAllEarnings: 'Donate All Earnings',
	donateCertainAmount: 'Donate Certain Amount',
	download: 'Download',
	downloading: 'Downloading...',
	downloadOriginalZip: 'Download original zip',
	downloadPdf: 'Download PDF',
	downloadProcessedFiles: 'Download processed files',
	draftGames: 'Draft Games',
	dragAndDropToUpload: 'Drag & Drop to Upload',
	duration: 'Duration',
	earlyTestBetter: 'The earlier you test, the better.',
	earnedThisPeriod: 'Earned this period',
	earnings: 'Earnings',
	earningsPerTrafficSourceNoDeveloperDomainWhatDoesThisMean: 'You earn %%organicLandingShare%% of the revenue on your Organic Landings. Your game earned %%organicLandingEarnings%% from Organic Landings and %%pokiPromotionEarnings%% from Promotions by Poki.',
	earningsPerTrafficSourceWhatDoesThisMean: 'You earn %%organicLandingShare%% of the revenue on your Organic Landings and %%developerDomainShare%% of the revenue on your Developer Domain. Your game earned %%organicLandingEarnings%% from Organic Landings, %%developerDomainEarnings%% on your Developer Domain and %%pokiPromotionEarnings%% from Promotions by Poki.',
	edit: 'Edit',
	editDomainCollection: 'Edit Domain Collection',
	editInvoiceInternalNotes: 'Edit Invoice Internal Notes',
	editPlaytestNotes: 'Edit playtest notes',
	editVersionNotes: 'Edit version notes',
	email: 'Email',
	emailAlreadyExistsError: 'Email already exists',
	emailChangeRequiresSignIn: 'Changing your email requires you to sign in again.',
	enableRevenueSharing: 'Enable Revenue Sharing',
	endMonth: 'End Month',
	engagement: 'Engagement',
	engagementWhatDoesThisMean: 'In this period, users on average were engaged with your game page for %%avgTotalTimeSpent%%. They spent %%avgPrePlayTime%% before playing the game, %%avgAdTime%% watching video ads,	and %%avgPlayTime%% playing the game.',
	enterGameTitle: 'Enter title of your game',
	error: 'Error',
	errorDetails: 'Error Details',
	errorDetailsDesc: 'Details for all of errors of the past 24 hours, updated hourly',
	errorHelpMessage: 'If issues continue, contact <a href="mailto:developersupport@poki.com">developersupport@poki.com</a>',
	errorPageMessage1: 'Welp, that didn\'t go as planned. Try again!',
	errorPageMessage2: 'Something went wrong - we\'ll try unplugging it and plugging it back in. Try again!',
	errorPageMessage3: 'We got rekt. Try again!',
	errorPageMessage4: 'Something\'s busted. Try again!',
	errorPageMessage5: 'Our bad, looks like something\'s straight up broken. Try again!',
	errorRadar: 'Error Radar',
	errorRadarDesc: 'Unique errors per minute per version for the last 30 minutes',
	errors: 'Errors',
	errorScanner: 'Error Scanner',
	errorScannerDesc: 'Unique errors per version per 10 minutes for the last 24 hours',
	estimatedPercentage: 'Estimated %',
	estimatedPercentageOfGameplays: 'Estimated percentage of gameplays',
	estimatedQuality: 'Estimated quality',
	euro: 'EUR €',
	exampleEmail: 'example@gmail.com',
	exclusive: 'Exclusive',
	exclusivity: 'Exclusivity',
	expired: 'Expired',
	expires: 'Expires',
	expiresIn: 'in %%duration%%',
	export: 'Export',
	exportCsv: 'Export CSV',
	exportCsvDescription: 'Please, keep this modal open until export is complete.',
	exporting: 'Exporting...',
	exportSourceData: 'Export Source Data',
	external: 'External',
	externalAds: 'External Ads',
	externalAdsDesc: 'Ads that are triggered in-game but on a non-Poki domain collection.',
	externalAdsShort: 'External',
	externalDomain: 'External Domain',
	externalDomainSavedSuccessfully: 'External domain saved successfully',
	externalResources: 'Because of security & compliance your game cannot load external resources. It all needs to be in the .zip file. Multiplayer game? Upload your game and <a href="mailto:developersupport@poki.com">contact&nbsp;us</a>.',
	feedback: 'Feedback',
	feelingYourGameIsReady: 'Feeling confident your game is ready for our Playground? Then let\'s make it real. We\'ll temporarily set your game live in order to evaluate its potential on Poki.',
	fieldRequired: 'This field is required',
	fileUpload: 'File Upload',
	filters: 'Filters',
	finance: 'Finance',
	finansiesBladsy: 'Finansies Bladsy',
	findNextPlayer: 'Find Next Player',
	flags: 'Flags',
	forbiddenProfileError: 'Something went wrong.<br />Try again or contact <a href="mailto:developersupport@poki.com">developersupport@poki.com</a>.',
	forceTeamCSP: 'Force Team CSP',
	forceTeamCSPTooltip: 'Use the team CSP instead of the strict CSP for un-approved games. This can be used for devs that want to test multiplayer games but haven\'t been approved or verified yet.',
	fromPreviousPeriod: 'from previous period',
	fullName: 'Full Name',
	game: 'Game',
	gameAlreadyHasPendingReviewWarningDescription: 'This request will replace the existing one. Your position in the queue will be kept intact.',
	gameAlreadyHasPendingReviewWarningTitle: 'This game already has a pending review',
	gameAnnotations: 'Game Annotations',
	gamebarDisplay: 'Gamebar Display',
	gameEmptyStateDesc: 'You\'ll see data for this game once it has been released.',
	gameEngine: 'Game Engine',
	gameEventIntegrationGuide: 'Game Event Integration Guide',
	gameEvents: 'Game Events',
	gameEventsGraph: 'Game Events Graph',
	gameID: 'Game ID',
	gameOrTeamNotFound: 'Game or team not found',
	gameplayMetricExplanation: 'A gameplay is counted when a player visits your game\'s page and has at least one gameplayStart event.',
	gameplays: 'Gameplays',
	gameplaysPerTrafficSourceNoDeveloperDomainWhatDoesThisMean: 'In this period your game had %%organicLandingGameplays%% gameplays from Organic Landings and %%pokiPromotionGameplays%% gameplays from Promotions by Poki.',
	gameplaysPerTrafficSourceWhatDoesThisMean: 'In this period your game had %%organicLandingGameplays%% gameplays from Organic Landings, %%developerDomainGameplays%% gameplays on your Developer Domain and %%pokiPromotionGameplays%% gameplays from Promotions by Poki.',
	gameplayStarts: 'Gameplay Starts',
	gameplaysThisPeriod: 'Gameplays this period',
	gameResolution: 'Game resolution',
	gameRevenueShareSettings: 'Game Revenue Share Settings',
	games: 'Games',
	gamesDisabledInUK: 'Game Temporarily Disabled in the UK',
	gamesDisabledInUKDesc: 'Due to an urgent privacy matter in the UK, <strong>%%gameTitle%%</strong> is temporarily disabled in the UK as it makes third-party requests that are not currently covered under our privacy policy. We\'re working with our legal team to fix this as soon as possible. Sorry for the inconvenience! If you have any questions, feel free to contact your account manager or reach out to <a href="mailto:developersupport@poki.com">developersupport@poki.com</a>',
	gameSettings: 'Game Settings',
	gameThumbnail: 'Game thumbnail',
	gameTitle: 'Game Title',
	gameTitleSuggestions: 'Need some inspiration? How about "%%titles[1]%%", "%%titles[2]%%" or "%%titles[3]%%"?',
	gameUploadTokenCopy: 'Remember to copy your token before navigating away from this page.',
	gameUploadTokenDescription: 'This secret token is used for continuous deployment with <a href="https://github.com/poki/poki-cli" target="_blank">poki-cli</a>. It should be set as the environment variable <strong>POKI_UPLOAD_TOKEN</strong>.',
	gameUploadTokenShare: 'Do not share this token with anyone, as it grants access to your deployment configurations.',
	gameURL: 'Game URL',
	gameWillBeLive: 'We’ll measure the performance and share it with you. If it’s a fit, there’s a deal to promote your game. If it is not, we can tell you what to improve.',
	general: 'General',
	generalSettings: 'General Settings',
	generatePlaytestLink: 'Generate Playtest Link',
	getPlayerFeedback: 'Get player feedback',
	githubMissingEmail: 'Your email needs to be public on GitHub in order to use this sign in option.',
	googleAnalyticsRemovedOnVersion: 'Google Analytics has been blocked for this game version in Europe due to privacy compliance.',
	goToVersions: 'Go to versions',
	greatThumbnail: 'Players from around the globe could see your thumbnail on the Poki Playground. Make sure it sticks out and shows off your game!',
	handledManually: 'Handled Manually',
	handleManually: 'Handle Manually',
	hardwareReportDisclaimer: 'Based on a random sampling of <strong>60 million players</strong> and last updated on <strong>%%lastUpdate%%.</strong>',
	hardwareReportSubtitle: 'The following charts give an overview of the various hardware and devices currently used by players on Poki. It is updated daily. This data was obtained according to the policies outlined in our privacy statement as available on our general Poki website.',
	hasActiveMG: 'Not possible due to an active MG',
	haveItCovered: 'I\'ve got it covered',
	hideGameplayerFooter: 'Hide gameplayer footer',
	hideSummary: 'Hide Summary',
	higher: 'Higher',
	hours: '%%amount%% hours',
	iAcceptThe: 'I accept the',
	imageMinResolutionError: 'The image needs to be at least %%minWidth%%x%%minHeight%%!',
	imageShouldBeSquareError: 'The width and height of the image should be the same!',
	important: 'Important',
	inactive: 'Inactive',
	inactiveVersions: 'Inactive versions',
	inbalanceInWise: 'Insufficient balance in Wise - deposit required',
	inbalanceInWiseDescription: 'Extra funds are required to pay out all prepared invoices.',
	industrySponsorship: 'Industry Sponsorship',
	ingameDisplay: 'In-game Display',
	ingameOrganicLanding: 'Ingame Organic Landing',
	ingamePokiPromotion: 'Ingame Poki Promotion',
	ingameTriggeredAds: 'In-Game Triggered Ads',
	ingameTriggeredAdsDesc: 'Ads that are triggered by an in-game event (ie. commercial breaks and in-game display).',
	ingameTriggeredAdsShort: 'In-Game',
	inspector: 'Inspector',
	internalNotes: 'Internal Notes',
	invalidVersion: 'Invalid version',
	invoice: 'Invoice',
	invoiceAdjustedBody: 'This invoice was manually adjusted by our Finance team to match your contract agreements.',
	invoiceAdjustedTitle: 'Invoice adjusted by Finance',
	invoiceInternalNotes: 'Invoice Internal Notes',
	invoiceMarkedAsPaidSuccessfully: 'Invoice marked as paid successfully',
	invoiceNumber: 'Invoice No.',
	invoicePeriod: 'Invoice Period',
	invoicePreparedInWiseError: 'Invoice could not be prepared in Wise',
	invoicePreparedInWiseSuccessfully: 'Invoice prepared in Wise successfully',
	invoices: 'Invoices',
	invoicesFundedSuccessfully: 'Invoices funded successfully',
	invoicesPreparedSuccessfully: 'Invoices prepared successfully',
	invoicing: 'Invoicing',
	isPaid: 'Is Paid',
	label: 'Label',
	labs: 'Labs',
	landingAds: 'Landing Ads',
	landingAdsDesc: 'Ads that are triggered in-game on the landing page.',
	landingAdsShort: 'Landing',
	large: 'Large',
	lastUpdated: 'Last updated',
	lastVisited: 'Last Visited',
	lastXDays: 'Last %%amount%% Days',
	learnBestPractices: 'Learn about the best practices in our <a href="https://developers.poki.com/guide" target="_blank" rel="noopener noreferrer">developer guide.',
	learnFirstPlayer: 'Get your first real players in. Watch them play and learn from how they break, fail, and enjoy your creation.',
	learnMore: 'Learn more',
	leavePageConfirmationDesc: "If you leave this page, you'll lose your changes",
	leavePageConfirmationTitle: 'You have unsaved changes',
	legalTypeOfAccount: 'Type of account',
	letsGo: 'Let\'s go!',
	letsUpload: 'Upload the latest version of your web build and give your game a title.',
	licenseFee: 'License Fee',
	liveForAWeek: 'Thousands of players will test your game on Poki.',
	loading: 'Loading...',
	loadingFinished: 'Loading finished',
	lower: 'Lower',
	manualAdjustment: 'Manual Earnings Correction',
	markAsPaid: 'Mark as paid',
	markAsPrepared: 'Mark as prepared',
	markedAsPaidAt: 'Marked as paid at',
	medium: 'Medium',
	midrollVideo: 'Midroll Video',
	minimumGuaranteeMonthlyAmountDescription: 'The currency of an Monthly MG is determined by the teams currency setting.',
	minimumGuaranteeMonths: 'Term Length',
	minimumGuaranteeMonthsPlaceholder: 'Choose a Term Length',
	minimumGuaranteeNotesDescription: 'Add a description or any notes relevant to this Monthly MG.',
	minimumGuarantees: 'Monthly Minimum Guarantees',
	minimumGuaranteesCannotBeSetInThePast: 'Monthly MGs cannot start today or in the past',
	minute: 'Minute',
	minutes: '%%amount%% minutes',
	mobile: 'Mobile',
	mobileCreateGame: 'Let\'s get your game hosted, for free. Uploading your web build is not yet supported on mobile. So sign in on a desktop computer to add your game.',
	monetization: 'Monetization',
	monetizationWhatDoesThisMeanVideoAndDisplay: 'In this period, daily active users on average saw %%videoAdRatio%% video ads earning you a total of %%videoAdEarnings%% and %%displayAdRatio%% display ads earning you a total of %%displayAdEarnings%% amounting to total earnings of %%totalEarnings%%',
	monetizationWhatDoesThisMeanVideoOnly: 'In this period, daily active users on average saw %%videoAdRatio%% video ads earning you a total of %%videoAdEarnings%%',
	monthlyAmount: 'Monthly Amount',
	moreActions: 'More Actions',
	mustUpdateBillingSettingsMessage: 'We are currently missing some information in your billing settings. Payments might be delayed until this is up to date. Please visit your Billing Settings page to update your information. If you have any questions or issues, please contact <a href="mailto:finance@poki.com">finance@poki.com</a>.',
	mustUpdateBillingSettingsTitle: 'Please update your billing settings',
	name: 'Name',
	nameVisibleNextToGames: 'Note: This name will be visible next to your games. it can be changed later.',
	negative: 'Negative',
	netDeveloperRevenue: 'Net Developer Revenue',
	netherlands: 'Netherlands',
	never: 'Never',
	new: 'New',
	newErrorPageDescription: 'Here you\'ll find an overview of any errors in your game. Click the specific error in the table below to see its stack trace and more info. If you\'ve got feedback or want to report an issue, let us know via <a href="mailto:developersupport@poki.com">email</a> or <a href="https://discord.gg/69mXZ7M">Discord</a>.',
	newErrorPageTitle: 'New!',
	newGameEngine: 'New game engine',
	newInvoicesTabDescription: 'Here you will find all invoices that have been sent to you from Poki for Developers. Invoices are run on a monthly basis and will be sent when your revenue hits €200 or more since your last payout.<br />Please contact <a href="mailto:developersupport@poki.com">developersupport@poki.com</a> if you have any questions.',
	newInvoicesTabTitle: 'Your finance tab!',
	next: 'next',
	no: 'No',
	noFileError: "Don't forget to upload a game!",
	noInvoiceInternalNotes: 'Click the pencil to edit invoice internal notes',
	noMatches: 'No Matches',
	noMatchesFound: '<strong>No matches were found</strong>, so here\'s a cat instead',
	nonExclusive: 'Non-exclusive',
	nonNLEU: 'Non-NL EU',
	noPlaytestNotes: 'Click the pencil to edit playtest notes',
	noRevShare: 'No Revenue Share',
	noRevShareWarning: 'You are about to sync a game to console that does not yet have a revenue share set up. Are you sure you want to sync this game?',
	notes: 'Notes',
	notesPlaceholder: 'Anything else we should know?',
	notFoundMessage1: 'We looked everywhere but can\'t seem to find what you\'re looking for.',
	notFoundMessage2: 'That page was around here somewhere but we can\'t seem to find it anymore.',
	notFoundMessage3: 'This is not the page you\'re looking for.',
	notifications: 'Notifications',
	notificationsDescription: 'Enable desktop notifications for when a new recording is available. *note* Keep this tab open to receive notifications.',
	notifiedByEmail: 'You will be notified by email when the performance review starts.',
	notPlaying: 'Not Playing',
	notStarted: 'Not started',
	noVersionNotes: 'Click the pencil to edit version notes',
	oneDay: '1 day',
	oneHour: '1 hour',
	oneMinute: '1 minute',
	oneOffPaymentNotesDescription: 'Add a description or any notes relevant to this One-Off Payment.',
	oneOffPayments: 'One-Off Payments',
	oneWeek: '1 week',
	only: 'Only',
	onlyPlayableOnPoki: 'Only playable on Poki',
	onlyPlayableOnPokiDescription: 'This game is only playable on Poki and all of our localized domains.',
	open: 'Open',
	openInInspector: 'Open in Inspector',
	operatingSystem: 'Operating system',
	optimizeImages: 'Optimize images',
	optimizeImagesDescription: 'Automatically reduce your version\'s file-size by optimizing images',
	optimizeImagesWarning: 'We highly recommend keeping this option enabled unless you have actively optimized your images yourself!',
	organicLandings: 'Organic Landings',
	other: 'Other',
	outsideEU: 'Outside EU',
	overview: 'Overview',
	paid: 'Paid',
	paused: 'Paused',
	paymentCycleInProgress: 'Current payment cycle in progress',
	paymentCycleInProgressText: 'We are currently in the process of paying out invoices from the past month so you will be unable to your edit billing settings until %%currentMonth%% 8, %%currentYear%%. If you need to change your billing settings for this current cycle, please get in touch with your Poki account manager or contact <a href="mailto:finance@poki.com">finance@poki.com</a>.',
	paymentMethod: 'Preferred Payment Method',
	payments: 'Payments',
	paypal: 'Paypal',
	paypalEmail: 'Paypal Email',
	pdf: 'PDF',
	pendingReviews: 'Pending reviews',
	permalinkFailed: 'Failed to copy permalink to clipboard',
	permalinkSuccess: 'Copied permalink to clipboard',
	personalMessage: 'Personal message',
	placeholderNameA: 'Jane Doe',
	placeholderNameB: 'John Doe',
	platform: 'Platform',
	platformDisplay: 'Platform Display',
	platformTriggeredAds: 'Poki Platform Triggered Ads',
	platformTriggeredAdsDesc: 'Ads that are on the game page and controlled by the Poki platform (ie. platform display and pre-rolls).',
	platformTriggeredAdsShort: 'Platform',
	play: 'Play',
	playableEverywhere: 'Playable everywhere',
	playableEverywhereDescription: 'This game is playable everywhere (including Poki). Check the box below to also allow this game to be played on mobile outside of Poki.',
	playAlarm: 'Play an alarm sound when a player connects',
	playerFeedback: 'Player Feedback',
	playerFeedbackDescription: 'Players can vote on your game with a 👍  or 👎 and add extra comments to give their response more context. We use these votes to calculate an overall rating out of 5.',
	playing: 'Playing',
	playtest: 'Playtests',
	playtestExpireReason: 'Playtest videos are deleted after 28 days due to privacy reasons.',
	playtestLink: 'Sharable player URL',
	playtestLogs: 'PokiSDK Logs',
	playtestNotes: 'Playtest notes',
	playtestRecordingNotification: 'New recordings are available for %%gameName%%',
	playtestsRequested: 'Playtests Requested',
	playTime: 'Play Time',
	poki: 'Poki',
	pokiCommunityAmbassador: 'Poki Community Ambassador',
	pokiPlatformDisplay: 'Poki Platform Display',
	pokiPromotions: 'Promotions by Poki',
	pokiSDK: 'Poki SDK',
	pokiWebFitTest: 'Poki Web Fit Test',
	positive: 'Positive',
	preapreInWise: 'Prepare in Wise',
	preferredCurrency: 'Preferred Currency',
	preferredCurrencyDesc: 'This determines the currency of all revenue stats and all future invoices.',
	preparedAndPaid: 'Prepared & Paid',
	prepareYourGameForWeb: 'Prepare your game for web',
	prePlayTime: 'Pre-play Time',
	preppingGame: 'Poki is preparing your game for 10,000 players.',
	prerollVideo: 'Preroll Video',
	pressEscToClear: 'press esc to clear',
	pressEscToQuit: 'press esc to quit',
	preview: 'Preview',
	previewInvoice: 'Preview Invoice',
	previewQrCode: 'Preview QR Code',
	previewSizes: 'Preview Sizes',
	primaryAddress: 'Primary Address',
	primaryContactName: 'Primary Contact Name',
	primaryCountry: 'Primary Country',
	privacyPolicyURL: 'Privacy Policy URL',
	privateNotes: 'Private notes',
	processing: 'Processing...',
	productionCosts: 'Production Costs',
	productSearchPlaceholder: 'Search for anything...',
	progress: 'Progress',
	publicVersion: 'Public Version',
	qaVersion: 'QA Version',
	rating: 'Rating',
	realStatus: 'Real Release Status: %%status%%',
	recipient: 'Recipient',
	recordedPlaytestDescription: 'Watch a recording of your game being played by a random Poki player.',
	recordedPlaytests: 'Recorded Playtests',
	recordingInProgress: 'Recordings in progress... %%requestLeft%%/%%requestCount%%',
	recordings: 'Recordings',
	recordingsRequested: '%%requestCount%% recordings have been requested. We are looking for players. It might take a while for recordings to appear here.',
	recurringMonthlyPaymentMonthlyAmountDescription: 'The currency of an Recurring Monthly Payment is determined by the teams currency setting.',
	recurringMonthlyPaymentNotesDescription: 'Add a description or any notes relevant to this Recurring Monthly Payment.',
	recurringMonthlyPayments: 'Recurring Monthly Payments',
	recurringMonthlyPaymentsCannotBeSetInThePast: 'Recurring Monthly Payments cannot start today or in the past',
	referAFriend: 'Refer a Friend',
	referredTeam: 'Referred Team',
	refreshPage: 'Refresh page',
	releaseYourGameOnPoki: 'Release your game on Poki',
	remove: 'Remove',
	removeApproval: 'Remove Approval',
	removeGame: 'Remove Game',
	removeUserConfirmationTitle: 'Are you sure you want to remove this user?',
	removeUserSuccess: 'User removed successfully',
	removing: 'Removing...',
	report: 'Report',
	reportUrl: 'Report url',
	requestChanges: 'Request changes',
	requested: 'Requested',
	requestRecordings: 'Request %%requestCount%% Recordings',
	requestReview: 'Request review',
	requestReviewIntro: 'Requesting a review of this version will send it to our team to test and determine if it can be set live. \n\nIf there are any specific points you\'d like us to check, please add them to the notes section below.',
	requestWebFitTest: 'Request a Web Fit Test',
	required: 'Required',
	reset: 'Reset',
	resources: 'Resources',
	response: 'Response',
	responses: 'Responses',
	returnToDashboard: 'Return to dashboard',
	revenueCorrection: 'Revenue Correction',
	revenueSharing: 'Revenue Sharing',
	revenueSharingDesc: 'The following settings allow you to set revenue shares on a team level or per game, for all ad types. It can take up to 24 hours for changes to take effect.',
	reviewChanges: 'Review Changes',
	reviewedBy: 'Reviewed by',
	reviewRequest: 'Review request',
	reviewRequested: 'Review requested',
	reviewRequestedMessage: 'We\'ll review this version as soon as possible. We aim to get back to you between <strong>3-5 business days.</strong><br /><br />You\'ll receive an e-mail with the results and can always check back here to see its status.',
	reviewRequestedTitle: 'Review requested!',
	reviews: 'Reviews',
	reviewStatus: 'Review status',
	reviewStatusApproved: 'Approved',
	reviewStatusClosed: 'Closed',
	reviewStatusDone: 'Ready',
	reviewStatusPending: 'Pending Review',
	reviewStatusRejected: 'Changes Requested',
	reviewStatusUploading: 'Processing...',
	revShare: 'Rev Share',
	rewardedBreaks: 'Rewarded Breaks',
	rewardedVideo: 'Rewarded Video',
	role: 'Role',
	save: 'Save',
	saveChanges: 'Save Changes',
	savedSuccessfully: 'Saved successfully',
	saveSettings: 'Save Settings',
	saving: 'Saving...',
	scriptError: 'What is a script error?',
	scriptErrorExplanation: 'The browser does not give us any additional information about this error. It is usually an error within an ad running in a different iframe which cannot be fixed from the game\'s side. However, this is not always the case so we still want to show it should you wish to investigate further.',
	searchPlaceholder: 'Search...',
	searchPokiPlayer: 'Watch random Poki player',
	seePlaytestResults: 'See playtest results',
	seePrivacyStatement: 'For information on processing of your personal data in the context of this web form please refer to <a href="https://poki.com/en/c/privacy-statement" target="_blank" rel="noopener noreferrer">Poki\'s Website Privacy Statement</a>.',
	selectACountry: 'Select a Country',
	selectADate: 'Select a Date',
	selectAGameEngine: 'Select a game engine',
	selectARole: 'Select a role',
	selectATeam: 'Select a Team',
	selectAType: 'Select a Type',
	selectAVersion: 'Select a version',
	selectCollections: 'Select Collections',
	selectCustomerSegment: 'Select a Customer Segment',
	selectPaymentMethod: 'Select a Payment Method',
	selectVatRegion: 'Select VAT Region',
	selectYear: 'Select Year',
	send: 'Send',
	sendDate: 'Send Date',
	sending: 'Sending...',
	sendingXInvoices: 'Sending %%amount%% invoices',
	sendNewSignInLink: 'Send New Sign In Link',
	sendWelcomeEmail: 'Send welcome email',
	sentAtX: 'Sent at %%dateTime%%',
	serverCosts: 'Server Costs',
	serverError: "Server error '%%error%%': %%message%%",
	serverUnreachable: 'Server unreachable. Check your connection or try reloading.',
	setActive: 'Set Active',
	setACustomLabel: 'Set a custom label',
	settings: 'Settings',
	settingsPageNotAvailabe: 'These settings are not available for unverified teams. ',
	showingWebglExtensionsResult: '<strong>Showing %%extensionsDisplayed%% results</strong> out of %%totalExtensions%%',
	showLess: 'show less',
	showXMore: 'show %%amount%% more',
	signIn: 'Sign in',
	signInUsingMagicLink: 'or sign in using a magic link',
	signInWithX: 'Sign in with %%provider%%',
	signout: 'Sign out',
	signoutCancel: 'No, go back',
	signoutConfirm: 'Yes, game over',
	signoutTitle: 'Are you sure you want to sign out?',
	signupPreferredPlatform: 'Sign up with your preferred platform',
	signUpWithX: 'Continue with %%provider%%',
	small: 'Small',
	somethingWentWrong: 'Something went wrong',
	stackTrace: 'Stack trace',
	startDate: 'Start Date',
	startGetPlayerFeedback: 'Great start! Now let\'s focus on getting your first feedback from players so you can improve your game.',
	startMonth: 'Start Month',
	startPlaytest: 'Start playtests',
	startPlaytesting: 'Start playtesting',
	startReview: 'Start review',
	status: 'Status',
	stopPlayback: 'Stop Playback',
	stopPlaytest: 'Stop Playtest',
	stopSearching: 'Stop Searching',
	submit: 'Submit',
	submitting: 'Submitting...',
	suggestedCategories: 'Suggested Categories (Max %%maxCategories%%)',
	suggestedDescription: 'Suggested Description',
	tablet: 'Tablet',
	takesAboutDays: 'Takes about 2-5 days.',
	team: 'Team',
	teamCode: 'Team Code',
	teamCodeDescription: 'Once set, <strong>this cannot be changed.</strong>',
	teamCodeExistsError: 'This team code is taken, please try another',
	teamHasNoUsers: 'There are no users in this team.',
	teamId: 'Team ID',
	teamName: 'Team Display Name',
	teamNameDescription: 'We\'ll use this throughout the site to refer to this team.',
	teamNotFound: 'Team not found',
	teamRevenueSharingSettings: 'Team Revenue Sharing Settings',
	teams: 'Teams',
	tempAuthIssuesDesc: 'There was an issue trying to authenticate you with our servers. Please check your connection and refresh the page or sign out and try again.',
	tempAuthIssuesTitle: 'Cannot authenticate',
	termsAndConditions: 'Terms & Conditions',
	testYourGame: 'Preview your game',
	thumbnail: 'Thumbnail',
	thumbnailPreview: 'Thumbnail Preview',
	thumbnailPreviewDesc: 'Your game thumbnail can appear in a few different sizes, take a look and make sure things are clear on all resolutions.',
	thumbnailTipsTitle: 'Game thumbnail tips:',
	timeAgo: '%%timeSince%% ago',
	timeOnPage: 'Time on page',
	timeSpent: 'Time Spent',
	timeSpentPerDAU: 'Time Spent per DAU',
	toastNewVersionActive: 'New Version Now Active',
	toastVersionLabelSaved: 'Version Label Saved',
	toBePaid: 'To Be Paid',
	topWebglExtensions: '<strong>Top %%extensionsDisplayed%%</strong> out of %%totalExtensions%%',
	total: 'Total',
	totalErrors: 'Total errors',
	trafficSources: 'By Traffic Source',
	trafficSourcesDesc: 'Explore insights based on how players discover your game.',
	translation: 'Translation',
	type: 'Type',
	unapproveGameDescription: 'Unapproved games cannot request reviews on versions and do not have access to a normal game dashboard.',
	unapproveGameTitle: 'Are you sure you want remove approval from this game?',
	unarchive: 'Unarchive',
	unavailable: 'Unavailable',
	unexpectedServerError: 'Unexpected server error (%%status%%). Try reloading.',
	unexpectedServerErrorShort: 'Unexpected server error.',
	unknown: 'Unknown',
	unknownEmailError: 'Hmm, we can\'t seem to find that email.<br />Try again or contact <a href="mailto:developersupport@poki.com">developersupport@poki.com</a>.',
	unsavedChanges: 'You have unsaved changes!',
	unsent: 'Unsent',
	unverifiedTeamDescription: 'Unverified teams have limited access to billing, finance and other features.',
	unverifyTeam: 'Unverify Team',
	unverifyTeamTitle: 'Are you sure you want to unverify this team?',
	upfrontPaymentAmountDescription: 'The currency of an Upfront Payment is determined by the teams currency setting.',
	upfrontPaymentNotesDescription: 'Add a description or any notes relevant to this Upfront Payment.',
	upfrontPayments: 'Upfront Payments',
	upfrontPaymentsCannotBeSetInThePast: 'Upfront Payments cannot start today or in the past',
	upfrontPaymentStartDateDescription: 'This is also the date on which the invoice will be sent to Finance.',
	upload: 'Upload',
	uploadAttractiveThumbnail: 'Upload an attractive game thumbnail',
	uploadAZip: 'Upload a .zip',
	uploadAZipOrFolder: 'Upload a .zip or drag & drop a folder',
	uploadedAt: 'Uploaded At',
	uploading: 'Uploading...',
	uploadNewVersion: 'Upload new version',
	uploadThumbnail: 'Upload thumbnail',
	uploadVersion: 'Upload New Version',
	uploadViaUrl: 'Upload game from URL',
	url: 'URL',
	usd: 'USD $',
	useInspector: 'Use the <a href="%%href%%" target="_blank" rel="noopener noreferrer">Inspector</a> to check your game.',
	userAlreadyExistsError: 'User already exists',
	userId: 'User ID',
	userRole: 'User Role',
	users: 'Users',
	userSettings: 'User Settings',
	usersWhatDoesThisMean: 'In this period, you reached an average of %%avgDau%% daily active users. %%c2p%% of those reached a gameplayStart() event,	leading to an average of %%avgDpu%% daily playing users.',
	validate_currency: '%%fieldName%% must be a valid currency amount',
	validate_email: "'%%value%%' is not a valid email address",
	validate_integer: '%%fieldName%% must be a whole number',
	validate_maxLength: '%%fieldName%% cannot be longer than %%args[1]%% characters',
	validate_minDate: '%%fieldName%% must be on or after %%args[1]%%',
	validate_minLength: '%%fieldName%% should be at least %%args[1]%% characters',
	validate_notEmpty: '%%fieldName%% is required',
	validate_positive: '%%fieldName%% must be a positive number',
	validate_slug: '%%fieldName%% is not a valid slug',
	validate_url: '%%value%% is not a valid URL',
	validate_validCharacters: '%%fieldName%% contains invalid characters',
	vatNumber: 'VAT Number',
	vatRegion: 'VAT Region',
	verifiedDeveloper: 'Verified Developer',
	verifiedTeam: 'Verified Team',
	verifiedTeamDescription: 'Verified teams have access to billing, finance and other features.',
	verifyTeam: 'Verify Team',
	verifyTeamTitle: 'Are you sure you want to verify this team?',
	version: 'Version',
	versionId: 'Version ID',
	versionNotes: 'Version notes',
	versionNotesPlaceholder: '- Added rewarded video integration\n- Fixed a game-breaking issue for IE10 users\n- Polished up button animations\n- …',
	versionPlayed: 'Version played',
	versions: 'Versions',
	viewAs: 'View as',
	viewReport: 'View report',
	viewRequest: 'View request',
	viewSummary: 'View Summary',
	waitingForPlayer: 'Waiting for a player to join ... This could take a while ..',
	warning: 'Warning',
	warningMiniumGuaranteeMessage: 'You\'re about to set a monthly minimum guarantee for a team that has one or more upfront payments active. Adding this minimum guarantee will pause the upfront payments.',
	watchMoreRecordings: 'Watch %%n%% more recordings to level up',
	watchNRecordings: 'Watch %%n%% playtest recordings to level up',
	webChallenges: 'Web comes with a few challenges. Get the basics covered.',
	webFitStarts: 'Web Fit Test start',
	webFitTestResults: 'Web Fit Test results',
	webFitTestResultsDesc: 'Once your game has been played by enough players, we\'ll share a review of its performance and potential on Poki via email.',
	webglRenderer: 'WebGL Renderer',
	webGuideBoxDescription: 'Discover if your game engine exports to web.',
	webGuideBoxTitle: 'Don\'t have a web build?',
	weeks: '%%amount%% weeks',
	welcomeDescription: 'Here you\'ll find stats on the performance and monetization of your games as well as your invoices and contact/billing information. <br />Have any questions? Get in touch with us at <a href="mailto:developersupport@poki.com">developersupport@poki.com</a>.',
	welcomeGameDescription: 'Let\'s start by adding your first game.',
	welcomeGameTitle: 'Welcome to Poki for Developers, %%name%%!',
	welcomeTitle: 'Welcome to Poki for Developers',
	welcomeToYourDashboard: 'Welcome to Poki for Developers!',
	whatDoesThisMean: 'What does this mean?',
	when: 'When',
	wireTransfer: 'Wire Transfer',
	wise: 'Wise',
	wiseError: 'Wise Error',
	wiseTransfer: 'Wise Transfer',
	xDays: '%%amount%% Days',
	xGames: '%%amount%% game(s)',
	xItemsSelected: '%%amount%% item(s) selected',
	xMinutes: '%%amount%% minutes',
	xMonths: '%%amount%% month(s)',
	xPlayersLeftResponses: '%%amount%% players left responses',
	xVotes: '%%votes%% votes',
	yes: 'Yes',
	yesImSure: "Yes, I'm sure",
	yesterday: 'Yesterday',
	yourGamePreviewLink: 'Your game preview link',
	yourName: 'Your Name',
	yourNotes: 'Your notes',

	thumbnailTipsList: `
		<li>Dimensions should be 628x628 or larger.</li>
		<li>Keep it simple. Try to feature the game's most prominent character/object, preferably in the center of the frame.</li>
		<li>Limit any text in the image. It can be hard to read in smaller sizes.</li>
	`,
};

export default function _(strings, replacements = {}) {
	const [id] = strings;

	if (!copy[id]) {
		throw new LocalizationError(`No copy found for '${id}'`);
	}

	const replacementsByIndex = [];
	let index = -1;

	// Replace all replacements with %%index%%
	const replaced = copy[id].replace(/%%([^%\s]+(\[[0-9]+\])?)%%/g, (__, key) => {
		index++;
		let replacement;

		const listMatch = key.match(/([^%\s[]+)\[([0-9]+)\]$/);
		if (!listMatch) {
			replacement = typeof replacements[key] !== 'undefined' ? replacements[key] : '';
		} else {
			if (!Array.isArray(replacements[listMatch[1]])) {
				throw new LocalizationError(`Localisation "${listMatch[1]}" expects an array of replacements`);
			}

			replacement = replacements[listMatch[1]][Number(listMatch[2]) - 1];
		}

		replacementsByIndex[index] = replacement;

		return `%%replacemewith-${index}%%`;
	});

	if (replacementsByIndex.length === 0) return replaced;

	// Split replaced into array to ensure we can inject components
	const replacedAsArray = replaced.split('%%');
	let resultHasObjects = false;

	const result = replacedAsArray.map(toBeReplaced => {
		const [replaceMeWith, idx] = toBeReplaced.split('-');

		if (replaceMeWith !== 'replacemewith') return toBeReplaced;

		const replacement = replacementsByIndex[idx];

		if (replacement instanceof Object) resultHasObjects = true;

		return replacement;
	});

	if (!resultHasObjects) return result.join('');

	return result;
}

export const parseEmoji = input => (
	twemoji.parse(input, {
		folder: 'svg',
		ext: '.svg',
		base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/',
	})
);

export const getReleaseStatusCopy = (release_status, admin = false, short = false, approved = false) => {
	if (!release_status) return 'not-released';

	if (admin) {
		if (short) {
			return release_status.replace('-with-content-restrictions', '-with-cr');
		}
		return release_status;
	}

	if (release_status.startsWith('limited-release') && !admin && !approved) {
		return 'web-fit-test-finished';
	}

	return release_status
		.replace('-with-content-restrictions', '')
		.replace('full-release', 'global-release')
		.replace('limited-release', 'global-release')
		.replace('10k-test', 'not-released')
		.replace('no-link-release', 'not-released');
};
