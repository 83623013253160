import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

const getButtonColor = props => (props.disabled ? props.theme.grey5 : props.positive ? props.theme.green1 : props.negative ? props.theme.rose1 : props.theme.pokiBlue);
const getButtonHoverColor = props => (props.positive ? props.theme.green3 : props.negative ? props.theme.rose5 : props.theme.pokiHover);

const getMarketingStyle = props => `
	display: inline-flex;
	position: relative;
	font-family: Torus,Montserrat,Arial,sans-serif;
	font-weight: bold;
	font-size: 21px;
	padding: 11px 35px 13px;
	color: ${props.theme.static.pureWhite};
	background-color: transparent;
	border-radius: 30px;
	text-decoration: none;
	line-height: 24px;
	white-space: nowrap;
	outline: none;
	justify-content: center;
	height: 48px;
	width: min-content;

	&:hover {
		color: ${props.theme.static.pureWhite};
	}

	${!props.secondary && !props.tertiary ? (
	/* Primary */ `
	background: ${getButtonColor(props)};
	color: ${props.theme.static.pureWhite};
	box-shadow: ${props.theme.boxShadowLarge};
	`) : !props.tertiary ? (
	/* Secondary */ `
	line-height: 18px;
	background: ${props.theme.static.pureWhite};
	border: 3px solid ${props.theme.static.pokiBlue};
	color: ${getButtonColor(props)};

	${!props.disabled && `
	:hover {
		border-color: ${getButtonHoverColor(props)};
		color: ${getButtonHoverColor(props)};
	}
		`}
	`) : (
	/* Tertiary */`
	color: ${props.theme.static.denimBlue};
	box-shadow: ${props.theme.static.boxShadowSmall};

	&:hover {
		background: ${props.theme.static.pokiBlue};
		color: ${props.theme.static.pureWhite};
	}
	`)}

	${!props.disabled && `
		cursor: pointer;
	`}
`;

const IconContainer = styled.span`
	vertical-align: center;
	width: 16px;
	height: 16px;

	svg {
		width: 100%;
		height: 100%;
	}

	${props => (props.$iconSide === 'left' ? `
	margin: -8px 4px -8px -4px;
	` : `
	margin: -8px 0 -8px 0;
	position: relative;
	left: 4px;
	`)}
`;

const getStyle = props => `
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 2px solid transparent;
	font-size: 16px;
	font-weight: bold;
	padding: 8px 16px;
	border-radius: 10px;
	transition: 0.1s ease-in-out;
	flex-shrink: 0;
	line-height: 1em;
	height: min-content;
	width: max-content;
	text-transform: capitalize;

	${!props.secondary ? (
	/* Primary */ `
	background: ${getButtonColor(props)};
	border-color: ${getButtonColor(props)};
	color: ${props.theme.static.pureWhite};
	max-width: 300px;
	min-width: 80px;

	${IconContainer} {
		[fill] {
			fill: ${props.theme.static.pureWhite};
		}
	}

	${!props.disabled ? `
	:hover {
		background: ${getButtonHoverColor(props)};
		border-color: ${getButtonHoverColor(props)};
		color: ${props.theme.static.pureWhite};
	}
	` : ''}
	`) : (
	/* Secondary */ `
	background: ${props.theme.pureWhite};
	border-color: ${getButtonColor(props)};
	color: ${getButtonColor(props)};

	${IconContainer} {
		[fill] {
			fill: ${props.theme.pokiBlue};
		}
	}

	${!props.disabled ? `
	:hover {
		border-color: ${getButtonHoverColor(props)};
		color: ${getButtonHoverColor(props)};
	}
	` : ''}
	`)}

	${props.disabled ? `
	color: ${props.theme.grey7};

	${IconContainer} {
		[fill] {
			fill: ${props.theme.grey7};
		}
	}
	` : ''}
`;

export const StyledButton = styled.button(props => (props.marketingButton ? getMarketingStyle(props) : getStyle(props)));
export const StyledAnchor = styled.a(props => (props.marketingButton ? getMarketingStyle(props) : getStyle(props)));

export const ButtonTextContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 24px 0 0;
`;

export const ButtonText = styled.div`
	font-size: 0.8em;
	margin-left: 10px;

	${props => props.warning && `
	font-weight: bold;
	color: ${props.theme.rose1};
	`}

	&:not(:last-child) {
		margin-right: 10px;
	}
`;

/**
 * Button component
 */
const Button = props => {
	const { className, children, submit, to, secondary, tertiary, positive, negative, marketingButton, onClick, onMouseEnter, disabled, openInNewTab, icon: Icon, iconSide = 'left' } = props;
	const location = useLocation();
	const navigate = useNavigate();

	let clickHandler;
	let Element;
	if (to && !disabled) {
		Element = StyledAnchor;
		clickHandler = e => {
			if (onClick) onClick(e);

			if (!to.match(/^(http|mailto:)/) && !openInNewTab) {
				e.preventDefault();

				// Replace history if pathname didn't change
				if (location.pathname === to) {
					navigate(to, { replace: true });
				} else {
					// Otherwise, push
					navigate(to);
				}

				// NOTE: this scroll to only works on the SITE because it scrolls window, and in the app window doesnt scroll (its a container)
				// NOTE 2: this scroll happens before navigation as `navigate` is async. Probably needs some native "react-router" way instead of this
				window.scrollTo(0, 0);
			} // Otherwise, it's just a link
		};
	} else {
		Element = StyledButton;
		if (onClick) {
			clickHandler = e => {
				e.preventDefault();
				onClick(e);
			};
		}
	}

	const button = (
		<Element
			className={className}
			onClick={clickHandler}
			secondary={secondary}
			tertiary={tertiary}
			disabled={disabled}
			negative={negative}
			positive={positive}
			rel={openInNewTab ? 'noopen noreferrer' : ''}
			target={openInNewTab ? '_blank' : undefined}
			href={to}
			type={submit ? 'submit' : 'button'}
			marketingButton={marketingButton}
			onMouseEnter={onMouseEnter}
		>
			{Icon && iconSide === 'left' && <IconContainer $iconSide={iconSide} secondary={secondary} tertiary={tertiary}><Icon /></IconContainer>}
			{children}
			{Icon && iconSide === 'right' && <IconContainer $iconSide={iconSide} secondary={secondary} tertiary={tertiary}><Icon /></IconContainer>}
		</Element>
	);

	return button;
};

export default Button;
